import { IICAConfigResult, IICASaS, ISlstArt } from '../types/ica.interface';
import { baseApi } from './baseApi';

const icaApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		icaGeneration: builder.mutation<any, string>({
			query: (icaVersion) => ({
				url: `chargingReport/icageneration/?version=${icaVersion}`,
				method: 'POST',
				responseHandler: async (response) => {
					if (response.status === 200) {
						const fileData = new Blob([await response.blob()], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						let link: any = document.createElement('a');
						link.href = window.URL.createObjectURL(fileData);
						//const appendVersion = versionId != undefined ? `_${versionId}` : '';
						link.download = `ICA-Report_${new Date().toISOString()}.xlsx`;
						link.click();
						return '';
					} else {
						return response.json();
					}
				},
			}),

			invalidatesTags: ['CostAllocation'],
		}),
		picasGeneration: builder.mutation<any, string>({
			query: (picasVersion) => ({
				url: `picasReport/generatepicasilv?versionId=0&version=${picasVersion}`,
				method: 'POST',
				responseHandler: async (response) => {
					if (response.status === 200) {
						const fileData = new Blob([await response.blob()], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						let link: any = document.createElement('a');
						link.href = window.URL.createObjectURL(fileData);
						//const appendVersion = versionId != undefined ? `_${versionId}` : '';
						link.download = `Picas-Report_${new Date().toISOString()}.xlsx`;
						link.click();
						return '';
					} else {
						return response.json();
					}
				},
			}),
			invalidatesTags: ['PicasAllocation'],
		}),
		newPicasGeneration: builder.mutation<any, string>({
			query: (picasVersion) => ({
				url: `picasReport/generatenewpicasilv?versionId=0&version=${picasVersion}`,
				method: 'POST',
				responseHandler: async (response) => {
					if (response.ok) {
						const fileData = new Blob([await response.blob()], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						let link: any = document.createElement('a');
						link.href = window.URL.createObjectURL(fileData);
						//const appendVersion = versionId != undefined ? `_${versionId}` : '';
						link.download = `Picas-Report_${new Date().toISOString()}.xlsx`;
						link.click();
						return '';

					} else {
						return response.json();
					}
				},
			}),
			invalidatesTags: ['NewPicasAllocation'],
		}),
		deleteICAVersion: builder.mutation<void, number>({
			query: (id) => ({
				url: `chargingReport/deleteicaversion/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CostAllocation'],
		}),
		deletePicasICAVersion: builder.mutation<void, number>({
			query: (id) => ({
				url: `picasReport/deletepicasica/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PicasAllocation'],
		}),
		deleteNewPicasICAVersion: builder.mutation<void, number>({
			query: (id) => ({
				url: `picasReport/deletenewpicasica/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['NewPicasAllocation'],
		}),
		getICAConfig: builder.query<IICAConfigResult[], void>({
			query: () => `chargingReport/geticaconfig`,
			providesTags: ['ICAConfig'],
		}),
		getSlstAlrt: builder.query<ISlstArt[], void>({
			query: () => `chargingReport/getslstart`,
			providesTags: ['ICASaS'],
		}),
		updateICAConfig: builder.mutation<any, { name: string; serviceId: string }>(
			{
				query: (body) => ({
					url: `chargingReport/updateicaconfig`,
					method: 'PUT',
					body: body,
				}),
				invalidatesTags: ['ICAConfig'],
			}
		),
		deleteICAConfig: builder.mutation<void, number>({
			query: (id) => ({
				url: `chargingReport/deleteicaconfig/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ICAConfig'],
		}),
		getICASaS: builder.query<IICASaS[], void>({
			query: () => `chargingReport/geticasas`,
			providesTags: ['ICASaS'],
		}),

		addICASaS: builder.mutation<IICASaS[], string>({
			query: (sas) => ({
				url: `chargingReport/saveicasas?SAs=${sas}`,
				method: 'POST',
			}),
			invalidatesTags: ['ICASaS'],
		}),
		deleteICASaS: builder.mutation<void, number>({
			query: (id) => ({
				url: `chargingReport/deleteicasas/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ICASaS'],
		}),
		updateICA: builder.mutation<any, { versionId: number; status: string }>(
			{
				query: ({ versionId, status }) => ({
					url: `chargingReport/updateica?versionId=${versionId}&status=${status}`,
					method: 'PUT',
				}),
				invalidatesTags: ['CostAllocation'],
			}
		),

		getICAGeneratedMonths: builder.query<any, void>({
			query: () => `chargingReport/geticageneratedmonths`,
		}),
		getServiceTypes: builder.query<any, { uploadType: string }>({
			query: ({ uploadType }) => `chargingReport/getservicetypes?uploadtype=${uploadType}`,
		}),
		generateCloudCosts: builder.mutation<any, { icaVersion: string, body: any }>({
			query: ({ icaVersion, body }) => ({
				url: `chargingReport/Azureicageneration/?version=${icaVersion}`,
				method: 'POST',
				body: body,
				responseHandler: async (response) => {
					if (response.status === 200) {
					const fileData = new Blob([await response.blob()], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					});
					let link: any = document.createElement('a');
					link.href = window.URL.createObjectURL(fileData);
					//const appendVersion = versionId != undefined ? `_${versionId}` : '';
					link.download = `ICA-Report_${new Date().toISOString()}.xlsx`;
					link.click();
					return '';
				} else {
					return response.json();
				}
				},
			}),

			invalidatesTags: ['CostAllocation'],
		}),
	}),
});

export const {
	useIcaGenerationMutation,
	usePicasGenerationMutation,
	useNewPicasGenerationMutation,
	useDeletePicasICAVersionMutation,
	useDeleteNewPicasICAVersionMutation,
	useDeleteICAVersionMutation,
	useGetICAConfigQuery,
	useGetSlstAlrtQuery,
	useUpdateICAMutation,
	useUpdateICAConfigMutation,
	useDeleteICAConfigMutation,
	useGetICASaSQuery,
	useAddICASaSMutation,
	useDeleteICASaSMutation,
	useGetICAGeneratedMonthsQuery,
	useGetServiceTypesQuery,
	useLazyGetServiceTypesQuery,
	useGenerateCloudCostsMutation,
} = icaApi;
