import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { commonState, getMailers, setMailers } from '../../store/commonSlice';
import { useGetAllUsersQuery } from '../../store/UserApi';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../input-field/input-field';
import Mailer from './mailer';
import { useMailResponsibleMutation } from '../../store/mailApi';
import { IMailer } from '../../types/common.interface';
import { toast } from 'react-toastify';

const MailServiceResponsible = ({
	defaultTo,
	defaultCC,
	defaultSubject,
}: {
	defaultTo: string[];
	defaultCC: string[];
	defaultSubject: string;
}) => {
	const dispatch = useDispatch();
	const mailers = useSelector(getMailers);
	const [mailerData, setMailerData] = useState<IMailer>();
	const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery();
	const [formData, setFormData] = useState<any>({});
	const [mailResponsible, { isLoading: isMailing }] =
		useMailResponsibleMutation();

	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setMailers({ serviceResponsible: false }))}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isMailing}
				disabled={
					mailerData?.emailIds.length === 0 ||
					mailerData?.subject.length === 0 ||
					mailerData?.message.length === 0
				}
				onClick={async () => {
					if (mailerData) {
						await mailResponsible(mailerData)
							.unwrap()
							.then((payoad: any) => toast.success('Mail Sent Successfully'))
							.catch((e: any) => {
								toast.error('Error Sending Mail');
							});
					}
					dispatch(setMailers({ serviceResponsible: false }));
				}}
			/>
		</div>
	);
	const mailerCallback = (data: any) => {
		setMailerData(data);
	};

	return (
		<Dialog
			header="Email Service Responsible"
			visible={mailers.serviceResponsible}
			style={{ width: '75vw' }}
			footer={footer}
			onHide={() => dispatch(setMailers({ serviceResponsible: false }))}
			className="comment-dialog"
		>
			<Mailer
				defaultTo={defaultTo}
				defaultCC={defaultCC}
				defaultSubject={defaultSubject}
				callback={mailerCallback}
			/>
		</Dialog>
	);
};

export default MailServiceResponsible;
