// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5.section-title {
  font-size: 1rem;
  font-weight: 700;
}

.p-datatable .p-datatable-header {
  background: transparent;
  border: none;
}
.p-datatable .p-datatable-header h5 {
  font-size: 1.138rem;
  font-weight: 600;
}

.p-treetable .p-treetable-header {
  background: transparent;
  border: none;
}
.p-treetable .p-treetable-header h5 {
  font-size: 1.138rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;AACJ;;AAII;EAEI,uBAAA;EACA,YAAA;AAFR;AAGO;EACH,mBAAA;EACA,gBAAA;AADJ;;AAQI;EAEI,uBAAA;EACA,YAAA;AANR;AAOO;EACH,mBAAA;EACA,gBAAA;AALJ","sourcesContent":["h5.section-title {\n    font-size: 1rem;\n    font-weight: 700;\n}\n\n.p-datatable\n{\n    .p-datatable-header\n    {\n        background: transparent;\n        border:none;\n       h5 {\n    font-size: 1.138rem;\n    font-weight: 600;\n    }\n    }\n}\n\n.p-treetable\n{\n    .p-treetable-header\n    {\n        background: transparent;\n        border:none;\n       h5 {\n    font-size: 1.138rem;\n    font-weight: 600;\n    }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
