import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IServiceData } from '.';
import AutoCompleteCostCenterId from '../../components/autocomplete-costcenter-id';
import AutoCompleteCostCenterName from '../../components/autocomplete-costcenter-name';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import DisplayIcon from '../../components/icon';
import { useGetOwnActiveCostCentersQuery } from '../../store/apiSlice';
import {
	getServiceOptions,
	getServicesToUpdate,
	setServiceOptions,
	setServicesToUpdate,
} from '../../store/commonSlice';
import {
	useReassignBulkServiceMutation,
	useReassignServiceMutation,
} from '../../store/servicesApi';
import { useGetCurrentUserQuery } from '../../store/UserApi';
import {
	IBulkService,
	IService,
	IUpdateCCRequest,
} from '../../types/service.interfaces';
import { checkAzureService } from '../../utils/utility-function';
import { ICostCenterData } from '../cost-center';
import DisplayReassignMonths from './display-reassign-months';

const BulkReassignDialog = ({
	selectedServices,
	costCenter,
	removeService,
}: {
	selectedServices: IService[];
	costCenter: ICostCenterData;
	removeService: (value: any) => any;
}) => {
	const showModal = useSelector(getServiceOptions).bulkReassignment;
	const dispatch = useDispatch();

	const { data: ownCostCenters = [], isLoading: isLoadingOwnCostCenters } =
		useGetOwnActiveCostCentersQuery();

	const [reAssignBulkService, { isLoading: isUpdating }] =
		useReassignBulkServiceMutation();

	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);
	const params = useParams();

	const servicesToSubmit = useSelector(getServicesToUpdate);

	const [selectedCategories, setSelectedCategories] = useState<any>([]);
	const [selectedCostCenter, setSelectedCostCenter] = useState<any>();

	const onCategoryChange = (e: { value: any; checked: boolean }) => {
		let _selectedCategories = [...selectedCategories];

		if (e.checked) {
			_selectedCategories.push(e.value);
		} else {
			for (let i = 0; i < _selectedCategories.length; i++) {
				const selectedCategory = _selectedCategories[i];

				if (selectedCategory.key === e.value.key) {
					_selectedCategories.splice(i, 1);
					break;
				}
			}
		}

		setSelectedCategories(_selectedCategories);
	};

	const renderFooter2 = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						dispatch(setServiceOptions({ bulkReassignment: false }));
						dispatch(setServicesToUpdate({ key: 'CLEAR' }));
					}}
					className="p-button-text"
				/>
				<Button
					label="Re-Assign"
					icon="pi pi-check"
					disabled={
						selectedServices.length === 0 ||
						(selectedCostCenter && selectedCostCenter.id == params.costCenterId)
					}
					onClick={async () => {
						if (currentUser) {
							const payload = {
								cc_Id: selectedCostCenter.id,
								comment: costCenter.id,
								cC_NTID: selectedCostCenter.user_responsible,
								isActuals: true,
								isDeputy: currentUser?.isDeputy,
								isOwner:
									ownCostCenters.findIndex(
										(cc: any) =>
											cc.costCenter === selectedCostCenter.target_costcenter
									) !== -1
										? true
										: false,
								isUser:
									ownCostCenters.findIndex(
										(cc: any) =>
											cc.costCenter === selectedCostCenter.target_costcenter
									) !== -1
										? false
										: !currentUser.role.isSuperAdmin,
								reason: '',
								reqUid: currentUser.id,
								source_NTID: costCenter.user_responsible,
								sourceCC: costCenter.target_costcenter,
								targetCC: selectedCostCenter.target_costcenter,
								versionId: 0,
								year: new Date().getFullYear(),
								services: servicesToSubmit?.map((item: any) => ({
									...item,
									additionalInformation: checkAzureService(item),
								})).map((item : any) => {
									if(item.additionalInfo === undefined && item.additionalInformation === undefined) {
										return {
											...item,
											additionalInformation : "null"
										}
									}
									return item
								})
							};

							console.log('PAYLOAD :: ', payload);

							await reAssignBulkService(payload)
								.unwrap()
								.then((response) => {
									dispatch(setServiceOptions({ bulkReassignment: false }));
									toast.success('Re-Assignment Request Successfully Placed.');
									removeService('ALL');
									dispatch(setServicesToUpdate({ key: 'CLEAR' }));
								})
								.catch((error: any) => {
									toast.error('Re-Assignment Request Failed.');
									dispatch(setServicesToUpdate({ key: 'CLEAR' }));
								});
						}
					}}
				/>
			</div>
		);
	};
	return (
		<Dialog
			header="Reassign Cost Center"
			visible={showModal}
			style={{ width: '50vw' }}
			onHide={() => dispatch(setServiceOptions({ bulkReassignment: false }))}
			footer={renderFooter2}
		>
			<h5>Target Cost Center</h5>

			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<div className="field col-6">
						<AutoCompleteCostCenterId
							defaultValue={{ id: Number(params.costCenterId) }}
							callback={(_output) => setSelectedCostCenter(_output)}
						/>
					</div>
					<div className="field col-6">
						<InputText
							name="target_responsible"
							value={selectedCostCenter?.target_responsible}
						/>
					</div>
				</div>
			</div>

			{selectedServices.map((_service) => (
				<div className="flex service-info align-items-center flex-column mb-4 transition-all transition-linear transition-duration-300 mt-2 pb-0">
					<div className="flex  align-items-center justify-content-between w-full">
						<h4>{_service.serviceName}</h4>
						<h4>{_service.serviceId}</h4>
					</div>

					<div className="flex align-items-between justify-content-between my-2 w-full">
						<div className="flex align-items-center">
							<DisplayIcon icon="info" className="info-icon" />
							<div className="flex align-items-center">
								<h4 className="info">
									{_service.additionalInfo && _service.additionalInfo.length > 0
										? _service.additionalInfo
										: '-'}
								</h4>
							</div>
						</div>
						<div className="flex align-items-center justify-content-between">
							<DisplayIcon icon="ticket" className="card-icon" />
							<div className="flex flex-column ml-1">
								<h3>
									{_service.ioNumber && _service.ioNumber.length > 0
										? _service.ioNumber
										: '-'}
								</h3>
							</div>
						</div>
					</div>
					<Divider />
					<div className="flex flex-column w-full">
						<h4 className="primary-text">Select Months to Re-Assign</h4>
						<div className="p-fluid grid formgrid mt-3">
							<div className="grid col-12">
								<div className="flex field col-12">
									<DisplayReassignMonths
										key={_service.id_pk}
										serviceDetail={_service}
										removeService={(serviceToRemove) => {
											removeService(serviceToRemove);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</Dialog>
	);
};

export default BulkReassignDialog;
