import { baseApi } from './baseApi';
import {
	IILVCommentRequest,
	ILVCommentRequest,
	IServiceDetails,
	
	ICommentResponse,
	ICommentRequest,
	IUpdateMapping,
	INewMapping,
	IHistoryRequest,
	IHistoryResponse,
	IUpdateCCRequest,
	IBulkServiceUpdate,
} from '../types/service.interfaces';
import { IServiceRequest } from '../views/service';


const servicesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllMonthlyServiceData: builder.mutation<
			IServiceDetails,
			IServiceRequest
		>({
			query: (serviceRequest) => ({
				url: `/actuals/getservicedata`,
				method: 'POST',
				body: serviceRequest,
			}),
		}),
		getILVComment: builder.mutation<any, IILVCommentRequest>({
			query: (ILVCommentRequest) => ({
				url: `actuals/getilvcomment`,
				method: 'POST',
				body: ILVCommentRequest,
				responseHandler: (response) => response.text(),
			}),
		}),
		getAllComments: builder.mutation<ICommentResponse, ICommentRequest>({
			query: (CommentRequest) => ({
				url: `actuals/getallcomments`,
				method: 'POST',
				body: CommentRequest,
			}),
		}),
		saveILVComment: builder.mutation<any, ILVCommentRequest>({
			query: (saveILVComment) => ({
				url: `actuals/ilvcomment`,
				method: 'POST',
				body: saveILVComment,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.id },
			],
		}),
		saveComment: builder.mutation<any, ICommentRequest>({
			query: (saveILVComment) => ({
				url: `actuals/savecomments`,
				method: 'POST',
				body: saveILVComment,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
			],
		}),
		reassignService: builder.mutation<any, IUpdateCCRequest>({
			query: (requestData) => ({
				url: `actuals/updatecc`,
				method: 'PUT',
				body: requestData,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
				{ type: 'Services', costCenterId: arg.comment },
				'Notifications',
			],
		}),
		rejectService: builder.mutation<any, Partial<IUpdateCCRequest> & { reason: string }>(
			{
				query: (requestData) => ({
					url: `actuals/decline`,
					method: 'POST',
					body: requestData,
				}),
				invalidatesTags: ['Notifications'],
			}
		),
		updateMappedService: builder.mutation<any, IUpdateMapping>({
			query: (requestData) => ({
				url: `serviceDetails/updatemappedservice`,
				method: 'PUT',
				body: requestData,
			}),
			invalidatesTags: ['Mapping'],
		}),
		addNewMapping: builder.mutation<any, INewMapping>({
			query: (requestData) => ({
				url: `serviceDetails/savemappedservice`,
				method: 'POST',
				body: requestData,
			}),
		}),
		getAllHistory: builder.mutation<IHistoryResponse[], IHistoryRequest>({
			query: (historyRequest) => ({
				url: `actuals/getcchistory`,
				method: 'POST',
				body: historyRequest,
			}),
		}),
		confirmMapping: builder.mutation<any, number[]>({
			query: (serviceIds) => ({
				url: `serviceDetails/updatewildcardservice`,
				method: 'PUT',
				body: { isMap: true, serviceId: serviceIds },
			}),
		}),
		unMapServices: builder.mutation<any, number[]>({
			query: (serviceIds) => ({
				url: `serviceDetails/updatewildcardservice`,
				method: 'PUT',
				body: { isMap: false, serviceId: serviceIds },
			}),
		}),
		mapNewServices: builder.mutation<
			any,
			{
				costCenterId: number;
				keyWord: string;
				serviceId: number[];
				isMapped: boolean;
			}
		>({
			query: ({ serviceId, costCenterId, keyWord, isMapped }) => ({
				url: `serviceDetails/mapnewservice`,
				method: 'PUT',
				body: {
					costCenterId: costCenterId,
					keyWord: keyWord,
					serviceId: serviceId,
					isMapped: isMapped,
				},
			}),
		}),
		reassignBulkService: builder.mutation<any, IBulkServiceUpdate>({
			query: (requestData) => ({
				url: `actuals/updateservices`,
				method: 'PUT',
				body: requestData,
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
				{ type: 'Services', costCenterId: arg.comment },
				'Notifications',
			],
		}),
	}),
});

export const {
	useGetILVCommentMutation,
	useGetAllMonthlyServiceDataMutation,
	useGetAllCommentsMutation,
	useSaveILVCommentMutation,
	useReassignServiceMutation,
	useSaveCommentMutation,
	useUpdateMappedServiceMutation,
	useAddNewMappingMutation,
	useGetAllHistoryMutation,
	useRejectServiceMutation,
	useConfirmMappingMutation,
	useUnMapServicesMutation,
	useMapNewServicesMutation,
	useReassignBulkServiceMutation
} = servicesApi;
