import { baseApi } from './baseApi';
import { IMailer, ISaveMailer } from '../types/common.interface';

const mailApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		mailResponsible: builder.mutation<any, IMailer>({
			query: (requestData) => ({
				url: `actuals/mailresponsible`,
				method: 'POST',
				body: requestData,
			}),
		}),
		mailAllUsers: builder.mutation<any, { subject: string; message: string }>({
			query: ({ subject, message }) => ({
				url: `user/mail`,
				method: 'POST',
				body: {
					subject,
					message,
				},
			}),
		}),
		mailICAReport: builder.mutation<any, IMailer>({
			query: (requestData) => ({
				url: `chargingReport/mailicareport`,
				method: 'POST',
				body: requestData,
			}),
		}),
		mailPicasICAReport: builder.mutation<any, IMailer>({
			query: (requestData) => ({
				url: `picasReport/mailpicasilv`,
				method: 'POST',
				body: requestData,
			}),
		}),
		mailNewPicasICAReport: builder.mutation<any, IMailer>({
			query: (requestData) => ({
				url: `picasReport/mailnewpicasilv`,
				method: 'POST',
				body: requestData,
			}),
		}),
		mailBugReport: builder.mutation<any, IMailer>({
			query: (requestData) => ({
				url: `user/reportbug`,
				method: 'POST',
				body: requestData,
			}),
		}),
		saveBugReport: builder.mutation<any, ISaveMailer>({
			query: (requestData) => ({
				url: `serviceDetails/insertbugreport`,
				method: 'POST',
				body: requestData,
			}),
		}),
		getBugReport: builder.query<ISaveMailer[], void>({
			query: () => `serviceDetails/bugreport`,
			providesTags: ['bugList'],
		}),
		updateBugStatus: builder.mutation<any, { trackingNumber: number; status: string }>({
			query: ({ trackingNumber, status }) => ({
				url: `serviceDetails/updatebugstatus?trackingNumber=${trackingNumber}&status=${status}`,
				method: 'PUT',
			}),
			invalidatesTags: ['bugList'],
		})
	}),
});

export const {
	useMailResponsibleMutation,
	useMailICAReportMutation,
	useMailPicasICAReportMutation,
	useMailNewPicasICAReportMutation,
	useMailAllUsersMutation,
	useMailBugReportMutation,
	useSaveBugReportMutation,
	useUpdateBugStatusMutation,
	useGetBugReportQuery,
} = mailApi;
