import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import {
	getICAExportModalSettings,
	setICAExportModals,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import { formatDateDDMMYYYYhhmm } from '../../utils/utility-function';
import { InputTextarea } from 'primereact/inputtextarea';
import { useUpdateMappedServiceMutation } from '../../store/servicesApi';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetClusterKeywordsQuery } from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import {
	useAddClusteringKeywordMutation,
	useDeleteClusteringKeywordMutation,
} from '../../store/commonApi';
import {
	useDeleteICAConfigMutation,
	useGetICAConfigQuery,
	useGetSlstAlrtQuery,
	useUpdateICAConfigMutation,
} from '../../store/icaApi';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { toast } from 'react-toastify';

const SlstAlert = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(getICAExportModalSettings);

	const [serviceId, setServiceId] = useState('');
	const [slstArtValue, setSlstArtValue] = useState('');

	const [deleteICAConfig, { isLoading: isICAConfigDeleting }] =
		useDeleteICAConfigMutation();
	const resetData = () => {
		setServiceId('');
	};
	const { data: ICAConfig = [], isFetching: isFetchingICAConfig } =
		useGetICAConfigQuery();
	const { data: SlstAlrt = [], isFetching: isFetchingSlstAlrt } =
		useGetSlstAlrtQuery();
	const [updateICAConfig, { isLoading: isICAUpdating }] =
		useUpdateICAConfigMutation();

	const clickAddSlstAlrt = () => {
		updateICAConfig({ serviceId: serviceId, name: slstArtValue });
		setServiceId('');
		setSlstArtValue('');
	};

	const deleteICA = (rowData: any) => {
		return (
			<DisplayIcon
				data-tip="Delete ICA Report"
				icon="tablerTrash"
				className="table-action"
				onClick={async () => {
					await deleteICAConfig(rowData.id)
						.unwrap()
						.then((payload) => {
							toast.success('ICA Report Deleted Successfully');
						})
						.catch((e: any) => {
							toast.error(e.data.message);
						});
				}}
			/>
		);
	};

	return (
		<Dialog
			header="Mapping"
			visible={openModal.slstArt}
			style={{ width: '50vw' }}
			onHide={() => {
				resetData();
				dispatch(setICAExportModals({ slstArt: false }));
			}}
		>
			<h5 className="section-title">Add Keyword</h5>
			<div className="p-fluid grid formgrid mt-3">
				<div className="grid col-12 align-items-center">
					<div className="field col-4 mt-3">
						<span className="p-float-label">
							<InputText
								id="serviceId"
								value={serviceId}
								onChange={(e) => setServiceId(e.target.value)}
							/>
							<label htmlFor="serviceId">Service ID</label>
						</span>
					</div>
					<div className="field col-4 mt-3">
						<CustomAutoComplete
							inputList={SlstAlrt}
							field="name"
							target="name"
							customId="slstalrt"
							placeholder="Slst Alrt"
							callback={(e) => {
								if (e) {
									setSlstArtValue(e.name);
								} else {
									setSlstArtValue('');
								}
							}}
						/>
					</div>
					<div className="field col-3 mt-3">
						<span className="p-float-label">
							<Button
								label="Add Keyword"
								icon="pi pi-check"
								className="p-button-sm"
								loading={isICAUpdating}
								disabled={serviceId.length === 0 || slstArtValue.length === 0}
								onClick={() => {
									clickAddSlstAlrt();
								}}
							/>
						</span>
					</div>
				</div>
			</div>

			<DataTable
				value={ICAConfig}
				responsiveLayout="scroll"
				loading={isFetchingICAConfig}
				tableClassName="ica-config"
			>
				<Column field="id" header="Id"></Column>
				<Column field="serviceId" header="Service Id" sortable></Column>
				<Column field="name" header="Name" sortable></Column>
				<Column header="Delete" body={deleteICA}></Column>
			</DataTable>
		</Dialog>
	);
};

export default SlstAlert;
