import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import DisplayIcon from '../../components/icon';

const MaintenancePage = () => {
	const navigation = useNavigate();
	return (
		<div
			className="flex flex-column justify-content-center"
			style={{ height: '75vh' }}
		>
			<div
				className="banner-message banner-message__logo"
				onClick={() => navigation('/actuals')}
			>
				<DisplayIcon icon="tablerLogo" className="mega-icon" />
				<h4>XCharge</h4>
			</div>

			<div className="banner-message">
				<DisplayIcon icon="tablerBarrier" className="mega-icon" />
				<h4>Maintenance In-Progress</h4>
				<DisplayIcon icon="tablerBarrier" className="mega-icon" />
			</div>
			<div
				className="flex justify-content-center"
				data-tip="Refresh Page / Check If Maintenance Mode is Over"
			>
				<Button
					icon="pi pi-refresh"
					className="p-button-rounded mr-4"
					aria-label="Filter"
					onClick={() => navigation('/actuals')}
				/>
			</div>
		</div>
	);
};

export default MaintenancePage;
function useNavigation() {
	throw new Error('Function not implemented.');
}
