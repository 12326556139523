// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-radiobutton label {
  font-size: 0.937rem;
}

.p-overlaypanel-content h5 {
  font-size: 0.937rem;
  color: blue;
  font-weight: 700;
}

.option-item h4.left {
  font-weight: 400;
  font-size: 0.937rem;
  color: #4d69fa;
}
.option-item h4.right {
  font-weight: 400;
  font-size: 0.937rem;
  color: #787b86;
}`, "",{"version":3,"sources":["webpack://./src/components/extra-actions/style.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGI;EACI,mBAAA;AAFR;;AAMI;EACI,mBAAA;EACA,WAAA;EACA,gBAAA;AAHR;;AAQI;EACI,gBAAA;EACA,mBAAA;EACA,cCnBU;ADclB;AAOI;EACI,gBAAA;EACA,mBAAA;EACA,cClBM;ADad","sourcesContent":["@import \"../../styles/colors\";\n\n.field-radiobutton {\n    label {\n        font-size: 0.937rem;\n    }\n}\n.p-overlaypanel-content {\n    h5{\n        font-size: 0.937rem;\n        color: blue;\n        font-weight: 700;\n    }\n}\n\n.option-item {\n    h4.left{\n        font-weight: 400;\n        font-size: 0.937rem;\n        color: $primary-color;\n    }\n    h4.right{\n        font-weight: 400;\n        font-size: 0.937rem;\n        color: $muted-color;\n    }\n}","$primary-color :  #4d69fa;\n$green : #22ab94;\n$lite:#FEFFFF;\n$text-color:#44434B;\n$muted-text-color: #787b86;\n$double-muted-text-color: #9598a1;\n$muted-color: #787b86;\n$double-muted-color: #9598a1;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
