// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hideButton {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0;
}

.icon-x {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.auto-complete {
  width: 100%;
}
.auto-complete .p-button {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  right: 2px;
  color: #0049f5;
}
.auto-complete .p-button:hover {
  background: transparent;
  color: #0049f5;
}
.auto-complete .p-inputtext {
  font-family: "Roboto Condensed";
  font-weight: 400;
  border-radius: 5px;
  width: 100%;
  padding-right: 3em;
  text-overflow: ellipsis;
  color: #44434B;
  background-color: rgba(0, 0, 0, 0.015);
  border: 1px solid rgb(225, 227, 230);
}
.auto-complete .p-inputtext:focus {
  padding-right: 2.25rem;
}
.auto-complete .p-autocomplete-loader {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/autocomplete/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,qBAAA;EACA,oBAAA;EACA,SAAA;AACJ;;AACA;EACI,oBAAA;EACA,6BAAA;AAEJ;;AAGA;EACI;IACE,UAAA;EAAJ;EAEE;IACE,mBAAA;IACA,UAAA;EAAJ;AACF;AAGA;EACI,WAAA;AADJ;AAEI;EACI,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EAEA,UAAA;EACA,cAAA;AADR;AAEQ;EACI,uBAAA;EACA,cAAA;AAAZ;AAGI;EACI,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA;EAIA,sCAAA;EACA,oCAAA;AAJR;AAAQ;EACA,sBAAA;AAER;AAII;EACI,kBAAA;AAFR","sourcesContent":[".hideButton {\n    visibility: hidden;\n    height: 0 !important;\n    width: 0 !important;\n    padding: 0 !important;\n    margin: 0 !important;\n    border: 0;\n}\n.icon-x{\n    animation: 1s fadeIn;\n    animation-fill-mode: forwards;\n}\n\n\n\n@keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      visibility: visible;\n      opacity: 1;\n    }\n  }\n\n.auto-complete{\n    width: 100%;\n    .p-button{\n        background: transparent;\n        border: none;\n        position: absolute;\n        right: 0;\n        // top: -1px;\n        right: 2px;\n        color: #0049f5;\n        &:hover{\n            background: transparent;\n            color: #0049f5;\n        }\n    }\n    .p-inputtext{\n        font-family: 'Roboto Condensed';\n        font-weight: 400;\n        border-radius: 5px;\n        width: 100%;\n        padding-right: 3em;\n        text-overflow: ellipsis;\n        color: #44434B;\n        &:focus{\n        padding-right: 2.25rem;\n        }\n        background-color: rgba(0, 0, 0, 0.015);\n        border: 1px solid rgb(225, 227, 230);\n        \n    }\n    .p-autocomplete-loader {\n        visibility: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
