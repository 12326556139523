import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';

import { useDispatch, useSelector } from 'react-redux';
import {
	getServiceOptions,
	getToken,
	setServiceOptions,
	getCostcenterId,
	setCostcenterId,
} from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import DisplayIcon from '../../components/icon';
import {
	useGetPicasDetailsQuery,
	useGetPicasExcelExportMutation,
	useLazyGetPicasDetailsQuery,
	useLazyGetNewPicasDetailsQuery
} from '../../store/picasApi';
import { useParams } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import useDownloadFile from '../../hooks/useDownloadFile';
import { formatCurrency } from '../../utils/utility-function';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TabView, TabPanel } from 'primereact/tabview';
import BaseFeeDialog from './base-fee-dialog';

export interface IPicasDetails {
	cc_For: string;
	month: string;
	year: number;
	months: string[];
	years: string[];
	summary: {
		quantity: number;
		unitPrice: number;
		service: string;
		total: number;
	}[];
	sum: number;
	report: {
		id_PK: number;
		asset_cc: string;
		owner: string;
		asset: string;
		ci_id: string;
		asset_ou: number;
		sap_cc_org: string;
		sap_item: string;
		price: number;
		costcenter_fk: number;
		picasReportId: number;
		uploadedBy: number;
		uploadedOn: string;
		uploadedmonth: string;
	}[];
}

const PicasDialog = () => {
	const params = useParams();
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
	const [newSelectedMonth, setNewSelectedMonth] = useState<string | null>(null);
	const [activeIndex, setActiveIndex] = useState(1);

	const monthOptions = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	// const [
	// 	fetchPicasValues,
	// 	{
	// 		data: serviceList,
	// 		isFetching: isFetchingServiceList,
	// 		isSuccess: isSuccessServiceList,
	// 		isError: isErrorServiceList,
	// 		error: errorServiceList,
	// 	},
	// ] = useLazyGetPicasDetailsQuery();

	const [
		fetchNewPicasValues,
		{
			data: newServiceList,
			isFetching: isFetchingNewServiceList,
			isSuccess: isSuccessNewServiceList,
			isError: isErrorNewServiceList,
			error: errorNewServiceList,
		},
	] = useLazyGetNewPicasDetailsQuery();

	useEffect(() => {
		const getAssetDetails = async () => {
			// const data = await fetchPicasValues({
			// 	ccId: Number(costCenterId),
			// 	month: selectedMonth,
			// 	year: new Date().getFullYear(),
			// }).unwrap();
			const newData = await fetchNewPicasValues({
				ccId: Number(costCenterId),
				month: newSelectedMonth,
				year: new Date().getFullYear(),
			}).unwrap();
			// if (selectedMonth === null) {
			// 	setSelectedMonth(data.months[data.months.length - 1]);
			// }
			if (newSelectedMonth === null) {
				setNewSelectedMonth(newData.months[newData.months.length - 1]);
			}
		};
		getAssetDetails();
	}, []);
	const token = useSelector(getToken);
	const openModal = useSelector(getServiceOptions);
	const [formData, setFormData] = useState<any>({});
	const [ILVComment, setILVComment] = useState('');
	const [comment, setComment] = useState('');
	const { downloadFile } = useDownloadFile();
	const [defaultMonth, setDefaultMonth] = useState('');
	const costCenterId = useSelector(getCostcenterId);
	const dispatch = useDispatch();
	const renderFooter = (name: any) => {
		return (
			<div>
				<Button
					label="No"
					icon="pi pi-times"
					onClick={() => {
						dispatch(setServiceOptions({ picasDetails: false }));
						dispatch(setCostcenterId(''));
					}}
					className="p-button-text"
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					onClick={() => {
						dispatch(setServiceOptions({ picasDetails: false }));
						dispatch(setCostcenterId(''));
					}}
				/>
			</div>
		);
	};

	let footerGroup = (
		// activeIndex === 0 ?
		// 	<ColumnGroup>
		// 		<Row>
		// 			<Column footer="Debit Sum:" colSpan={3} />
		// 			<Column
		// 				footer={
		// 					serviceList &&
		// 					formatCurrency(
		// 						serviceList.summary.reduce((acc, d) => (acc += d.total), 0)
		// 					)
		// 				}
		// 				colSpan={1}
		// 			/>
		// 		</Row>
		// 	</ColumnGroup> :
			<ColumnGroup>
				<Row>
					<Column footer="Debit Sum:" colSpan={2} />
					<Column
						footer={
							newServiceList &&
							formatCurrency(
								newServiceList.summary.reduce((acc, d) => (acc += d.total), 0)
							)
						}
						colSpan={1}
					/>
				</Row>
			</ColumnGroup>
	);

	const actions = (rowdata: any) => {
		const category = rowdata.category;
		return (
			<>
				{category.includes("Base Fee") &&
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltip="Base fee info"
						tooltipOptions={{ position: 'top' }}
						onClick={(e: any) => {
							e.stopPropagation();
							dispatch(setServiceOptions({ showBaseFeeDialog: true }));
							return true;
						}}
					>
						<DisplayIcon className="table-action" icon="info" />
					</Button>
				}
			</>
		)
	}

	return (
		<Dialog
			header="Workplace Details"
			visible={openModal.picasDetails}
			style={{ width: '75vw' }}
			onHide={() => {
				setTimeout(() => {
					dispatch(setCostcenterId(''));
					dispatch(setServiceOptions({ picasDetails: false }));
				}, 50)
			}}
		>


			{/* <TabView className='picas-tab' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
				<TabPanel header="Picas Report">
					<div className="flex justify-content-evenly p-1 align-items-center">
						<div className="flex flex-column align-items-center">
							<h5>{`Cost Center Report For ${serviceList?.cc_For}, Year : ${serviceList?.year}`}</h5>
							<div className="mt-4">
								Month:
								<Dropdown
									className="ml-4"
									value={selectedMonth}
									defaultValue={serviceList?.month}
									options={serviceList?.months}
									onChange={async (e) => {
										setSelectedMonth(e.value);
										await fetchPicasValues({
											ccId: Number(costCenterId),
											month: e.value,
											year: new Date().getFullYear(),
										});
										// await fetchNewPicasValues({
										// 	ccId: Number(costCenterId),
										// 	month: e.value,
										// 	year: new Date().getFullYear(),
										// }).catch(err => console.log(err));
									}}
									placeholder="Select Month"
								/>
							</div>
						</div>

						<DisplayIcon
							icon="excel"
							className="excel-icon"
							onClick={() => {
								downloadFile(
									`picasReport/exportdetails?ccId=${Number(
										costCenterId
									)}&monthYear=${selectedMonth}-${new Date().getFullYear()}`,
									`Picas Details-Report_${new Date().toISOString()}.xlsx`
								);
							}}
						/>
					</div>

					<h5 className="mt-5">Conclusion</h5>
					<div className="cards my-3">
						<DataTable
							loading={isFetchingServiceList}
							value={serviceList?.summary}
							responsiveLayout="scroll"
							rowHover
							onClick={(e) => e.stopPropagation()}
							footerColumnGroup={footerGroup}
						>
							<Column field="quantity" header="Quantity"></Column>
							<Column
								field="unitPrice"
								header="Unit Price"
								body={(data) => formatCurrency(data.unitPrice)}
							></Column>
							<Column field="service" header="Service / Type"></Column>
							<Column
								field="total"
								header="Total"
								body={(data) => formatCurrency(data.total)}
							></Column>
						</DataTable>
					</div>
					<h5 className="mt-5">Details</h5>
					<div className="cards my-3">
						<DataTable
							value={serviceList?.report}
							responsiveLayout="scroll"
							rowHover
							onClick={(e) => e.stopPropagation()}
						>
							<Column field="sap_item" header="SAP Item"></Column>
							<Column field="ci_id" header="CI ID"></Column>
							<Column field="asset" header="Object Item"></Column>
							<Column field="owner" header="User"></Column>
						</DataTable>
					</div>
				</TabPanel>


				<TabPanel header="Workplace Report"> */}
					{/* Workplace report starts */}
					<div className="flex justify-content-evenly p-1 align-items-center">
						<div className="flex flex-column align-items-center">
							{/* <h5>{`Cost Center Report For ${serviceList?.cc_For}, Year : ${serviceList?.year}`}</h5> */}
							<div className="mt-4">
								Month:
								<Dropdown
									className="ml-4"
									value={newSelectedMonth}
									defaultValue={newServiceList?.month}
									options={newServiceList?.months}
									onChange={async (e) => {
										setNewSelectedMonth(e.value);
										// await fetchPicasValues({
										// 	ccId: Number(costCenterId),
										// 	month: e.value,
										// 	year: new Date().getFullYear(),
										// });
										await fetchNewPicasValues({
											ccId: Number(costCenterId),
											month: e.value,
											year: new Date().getFullYear(),
										});
									}}
									placeholder="Select Month"
								/>
							</div>
						</div>

						<DisplayIcon
							icon="excel"
							className="excel-icon"
							onClick={() => {
								downloadFile(
									`picasReport/exportnewpicas?costcenterId=${Number(
										costCenterId
									)}&month=${newSelectedMonth}&year=${new Date().getFullYear()}`,
									`Workplace Details-Report_${new Date().toISOString()}.xlsx`
								);
							}}
						/>
					</div>

					<h5 className="mt-5">Conclusion</h5>
					<div className="cards my-3">
						<DataTable
							loading={isFetchingNewServiceList}
							value={newServiceList?.summary}
							responsiveLayout="scroll"
							rowHover
							onClick={(e) => e.stopPropagation()}
							footerColumnGroup={footerGroup}
						>
							<Column field="quantity" header="Quantity" sortable></Column>
							<Column field="category" header="Category" sortable></Column>
							<Column field="total" header="Total" body={(data) => formatCurrency(data.total)} sortable></Column>
							<Column align="center" body={actions}></Column>
						</DataTable>
					</div>
					<h5 className="mt-5">Details</h5>
					<div className="cards my-3">
						<DataTable
							value={newServiceList?.report}
							responsiveLayout="scroll"
							rowHover
							onClick={(e) => e.stopPropagation()}
						>
							<Column field="category" header="SAP Item" sortable></Column>
							<Column field="ci_id" header="CI ID" sortable></Column>
							<Column field="asset" header="Object Item" sortable></Column>
							<Column field="owner" header="User" sortable></Column>
							<Column field="price" header="Price" body={(data) => formatCurrency(data.price)} sortable></Column>
						</DataTable>
					</div>
				{/* </TabPanel>
			</TabView> */}
			<BaseFeeDialog></BaseFeeDialog>
		</Dialog>
	);
};

export default PicasDialog;
