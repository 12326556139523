import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IServiceData } from '.';
import AutoCompleteCostCenterId from '../../components/autocomplete-costcenter-id';
import AutoCompleteCostCenterName from '../../components/autocomplete-costcenter-name';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { useGetOwnActiveCostCentersQuery } from '../../store/apiSlice';
import {
	getServiceOptions,
	setServiceOptions,
	setServicesToUpdate,
} from '../../store/commonSlice';
import { useReassignServiceMutation } from '../../store/servicesApi';
import { useGetCurrentUserQuery } from '../../store/UserApi';
import { IUpdateCCRequest } from '../../types/service.interfaces';
import { checkAzureService } from '../../utils/utility-function';
import { ICostCenterData } from '../cost-center';

const ReassignDialog = ({
	monthlyData,
	costCenter,
}: {
	monthlyData: IServiceData;
	costCenter: ICostCenterData;
}) => {
	const showModal = useSelector(getServiceOptions).reAssignDialog;
	const dispatch = useDispatch();
	const [selectedCostCenter, setSelectedCostCenter] = useState<any>();

	const { data: ownCostCenters = [], isLoading: isLoadingOwnCostCenters } =
		useGetOwnActiveCostCentersQuery();

	const [reAssignService, { isLoading: isUpdating }] =
		useReassignServiceMutation();

	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const [selectedCategories, setSelectedCategories] = useState<any>([]);

	const onCategoryChange = (e: { value: any; checked: boolean }) => {
		let _selectedCategories = [...selectedCategories];

		console.log(':: e ::', e);
		console.log(':: selected category ::', _selectedCategories);
		if (e.checked) {
			_selectedCategories.push(e.value);
		} else {
			for (let i = 0; i < _selectedCategories.length; i++) {
				const selectedCategory = _selectedCategories[i];

				if (selectedCategory.serviceMonth === e.value.serviceMonth) {
					_selectedCategories.splice(i, 1);
					break;
				}
			}
		}

		setSelectedCategories(_selectedCategories);
	};

	const renderFooter2 = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => dispatch(setServiceOptions({ reAssignDialog: false }))}
					className="p-button-text"
				/>
				<Button
					label="Re-Assign"
					icon="pi pi-check"
					disabled={
						!selectedCostCenter ||
						costCenter.target_costcenter ===
							selectedCostCenter.target_costcenter ||
						selectedCategories.length === 0
					}
					onClick={async () => {
						console.log(monthlyData);
						console.log(costCenter);
						console.log(selectedCostCenter);
						console.log(selectedCategories);
						if (currentUser) {
							const payload: IUpdateCCRequest = {
								comment: monthlyData.cc_Id,
								serviceId: monthlyData.serviceId,
								serviceName: monthlyData.serviceName,
								said: monthlyData.said,
								additionalInformation: checkAzureService(monthlyData),
								year: monthlyData.year,
								cc_Id: selectedCostCenter.id,
								targetCC: selectedCostCenter.target_costcenter,
								source_NTID: costCenter.user_responsible,
								sourceCC: costCenter.target_costcenter,
								months: selectedCategories.map((e: any) => e.serviceMonth),
								isOwner:
									ownCostCenters.findIndex(
										(cc: any) =>
											cc.costCenter === selectedCostCenter.target_costcenter
									) !== -1
										? true
										: false,
								isUser:
									ownCostCenters.findIndex(
										(cc: any) =>
											cc.costCenter === selectedCostCenter.target_costcenter
									) !== -1
										? false
										: !currentUser.role.isSuperAdmin,
								cC_NTID: selectedCostCenter.user_responsible,
								reqUid: currentUser.id,
								isActuals: true,
								planned: selectedCategories.reduce(
									(acc: number, service: any) => (acc += service.plannedAmount),
									0
								),
								booked: selectedCategories.reduce(
									(acc: number, service: any) => (acc += service.bookedAmount),
									0
								),
								responsible: monthlyData.responsible,
								ioNumber: monthlyData.ioNumber,
								isDeputy: currentUser?.isDeputy,
								isAdmin: currentUser.role.isAdmin,
								isSuperAdmin: currentUser.role.isSuperAdmin,
							};
							console.log(payload);
							await reAssignService(payload)
								.unwrap()
								.then((payload: any) => {
									if(currentUser.role.isSuperAdmin) {
										toast.success('Re-Assignment Successful.');
									} else {
										toast.success('Re-Assignment Request Successfully Placed.');
									}
								})
								.catch((error: any) => {
									toast.error('Re-Assignment Request Failed.');
								});
							dispatch(setServiceOptions({ reAssignDialog: false }));
							dispatch(setServiceOptions({ serviceDetails: false }));
						}
					}}
				/>
			</div>
		);
	};
	return (
		<Dialog
			header="Reassign Cost Center"
			visible={showModal}
			style={{ width: '50vw' }}
			onHide={() => dispatch(setServiceOptions({ reAssignDialog: false }))}
			footer={renderFooter2}
		>
			<h5>Mapping</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<div className="field col-6">
						<AutoCompleteCostCenterId
							defaultValue={{ id: costCenter.id }}
							callback={(_output) => setSelectedCostCenter(_output)}
						/>
					</div>
					<div className="field col-6">
						<InputText
							name="target_responsible"
							value={selectedCostCenter?.target_responsible}
						/>
					</div>
				</div>
			</div>
			<h5>Select Months to Re-Assign</h5>
			<div className="p-fluid grid formgrid mt-5">
				<div className="grid col-12">
					<div className="flex field col-12">
						{monthlyData &&
							monthlyData.data.map((category) => {
								return (
									<div key={category.month} className="field-checkbox mr-4">
										<Checkbox
											inputId={category.month}
											name="months"
											value={category}
											onChange={onCategoryChange}
											checked={selectedCategories.some(
												(item: any) => item.month === category.month
											)}
											disabled={
												category.bookedAmount === category.plannedAmount
											}
											tooltip={
												category.bookedAmount === category.plannedAmount
													? 'Booked services cannot be re-assigned'
													: ''
											}
											tooltipOptions={{ position: 'top' }}
										/>
										<label htmlFor={category.month}>{category.month}</label>
									</div>
								);
							})}
						<div className="field-checkbox mr-4">
							{monthlyData.data.filter(
								(item) => item.bookedAmount !== item.plannedAmount
							).length === 0 ? (
								<Message severity="warn" text="All Services Are Booked" />
							) : selectedCategories.length ===
							  monthlyData.data.filter(
									(item) => item.bookedAmount !== item.plannedAmount
							  ).length ? (
								<>
									{' '}
									<Checkbox
										inputId="no-cc"
										name="no-months"
										value={0}
										onChange={(e) => {
											setSelectedCategories([]);
										}}
										checked={
											selectedCategories.length ===
											monthlyData.data.filter(
												(item) => item.bookedAmount !== item.plannedAmount
											).length
										}
									/>
									<label htmlFor="no-cc">Unselect All</label>
								</>
							) : (
								<>
									{' '}
									<Checkbox
										inputId="all-cc"
										name="all-months"
										value={1}
										onChange={(e) => {
											setSelectedCategories([
												...monthlyData.data.filter(
													(item) => item.bookedAmount !== item.plannedAmount
												),
											]);
										}}
										checked={
											selectedCategories.length ===
											monthlyData.data.filter(
												(item) => item.bookedAmount !== item.plannedAmount
											).length
										}
									/>
									<label htmlFor="all-cc">Select All</label>{' '}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ReassignDialog;
