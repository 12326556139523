// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-multiselect .p-multiselect-label-container .p-multiselect-items-label {
  padding: 0.6rem 0.9rem;
  font-family: "Roboto Condensed";
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  width: 100%;
  padding-right: 3em;
  text-overflow: ellipsis;
  color: #44434B;
  background-color: rgba(0, 0, 0, 0.015);
}
.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  color: #4d69fa;
}`, "",{"version":3,"sources":["webpack://./src/views/charging-report-pivot/index.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGQ;EACI,sBAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,sCAAA;AAFZ;AAOQ;EACI,cCnBM;ADclB","sourcesContent":["@import \"../../styles/colors\";\n.p-multiselect{\n    .p-multiselect-label-container{\n        .p-multiselect-items-label{\n            padding:0.6rem 0.9rem;\n            font-family: \"Roboto Condensed\";\n            font-size: 1rem;\n            font-weight: 400;\n            border-radius: 5px;\n            width: 100%;\n            padding-right: 3em;\n            text-overflow: ellipsis;\n            color: #44434B;\n            background-color: rgba(0, 0, 0, 0.015);\n//            border: 1px solid rgb(225, 227, 230);\n        }\n    }\n    .p-multiselect-trigger{\n        .p-multiselect-trigger-icon{\n            color: $primary-color;\n        }\n    }\n}","$primary-color :  #4d69fa;\n$green : #22ab94;\n$lite:#FEFFFF;\n$text-color:#44434B;\n$muted-text-color: #787b86;\n$double-muted-text-color: #9598a1;\n$muted-color: #787b86;\n$double-muted-color: #9598a1;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
