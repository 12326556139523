// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-float-label label {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 0.978rem;
}

.p-inputtext {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 1rem;
  color: gray;
}

.p-inputtext:enabled:hover, .p-inputtext:enabled:focus {
  border-width: 0.5px;
  border-color: #6692f9;
}

.custom-placeholder .p-inputtext::placeholder {
  opacity: 0;
}

.custom-placeholder .p-inputtext:focus::placeholder {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/input-field/style.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;EACA,gBAAA;EACA,mBAAA;AAAR;;AAIA;EAEI,+BAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;AAFJ;;AAMA;EACI,mBAAA;EACA,qBAAA;AAHJ;;AAMA;EACI,UAAA;AAHJ;;AAKA;EACI,UAAA;AAFJ","sourcesContent":[".p-float-label{\n    label{\n        font-family: 'Roboto Condensed';\n        font-weight: 400;\n        font-size: 0.978rem;\n        \n    }\n}\n.p-inputtext{\n\n    font-family: 'Roboto Condensed';\n    font-weight: 400;\n    font-size: 1rem;\n    color: gray;\n    \n}\n\n.p-inputtext:enabled:hover, .p-inputtext:enabled:focus{\n    border-width: 0.5px;\n    border-color: #6692f9;\n}\n\n.custom-placeholder .p-inputtext::placeholder {\n    opacity: 0;\n}\n.custom-placeholder .p-inputtext:focus::placeholder {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
