import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import {
	getICAExportModalSettings,
	setICAExportModals,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import { formatDateDDMMYYYYhhmm } from '../../utils/utility-function';
import { InputTextarea } from 'primereact/inputtextarea';
import { useUpdateMappedServiceMutation } from '../../store/servicesApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetClusterKeywordsQuery } from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import {
	useAddClusteringKeywordMutation,
	useDeleteClusteringKeywordMutation,
} from '../../store/commonApi';
import {
	useAddICASaSMutation,
	useDeleteICASaSMutation,
	useGetICASaSQuery,
} from '../../store/icaApi';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

const SAConfiguration = () => {
	const dispatch = useDispatch();
	const openModal = useSelector(getICAExportModalSettings);

	const [said, setSaid] = useState('');

	const [deleteICASaS, { isLoading: isICASaSAdding }] =
		useDeleteICASaSMutation();
	const resetData = () => {
		setSaid('');
	};
	const { data: icaSaS = [], isFetching } = useGetICASaSQuery();
	const [addICASaS, { isLoading: isClusteringUpdating }] =
		useAddICASaSMutation();

	const [deleteModal, setDeleteModal] = useState(false);
	const [id, setId] = useState(0);
	const clickAddICASaS = async () => {
		await addICASaS(said)
			.unwrap()
			.then((payload) => {
				toast.success('SA Configuration Added Successfully!');
			})
			.catch((e: any) => {
				toast.error('Error Deleting SA Configuration');
			});
		setSaid('');
	};

	const deleteKeyword = (rowData: any) => {
		ReactTooltip.rebuild();
		return (
			<DisplayIcon
				icon="tablerTrash"
				data-tip="Delete SA Configuration"
				className="table-action"
				onClick={() => {
					setId(rowData.id);
					setDeleteModal(true);
				}}
			/>
		);
	};
	const deleteFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						setDeleteModal(false);
						setId(0);
					}}
					className="p-button-text"
				/>
				<Button
					label="Delete"
					icon="pi pi-trash"
					onClick={async () => {
						await deleteICASaS(id)
							.unwrap()
							.then((payload) => {
								toast.success('SA Configuration Successfully!');
							})
							.catch((e: any) => {
								toast.error('Failed Deleting SA Configuration');
							});
						setDeleteModal(false);
					}}
				/>
			</div>
		);
	};

	return (
		<>
			<Dialog
				header="SA Configuration"
				visible={openModal.saConfiguration}
				style={{ width: '50vw' }}
				onHide={() => {
					resetData();
					dispatch(setICAExportModals({ saConfiguration: false }));
				}}
			>
				<h5 className="section-title">Add SAID</h5>
				<div className="p-fluid grid formgrid mt-3">
					<div className="grid col-12 align-items-center">
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									id="costCenter"
									value={said}
									onChange={(e) => setSaid(e.target.value)}
								/>
								<label htmlFor="costCenter">SAID</label>
							</span>
						</div>
						<div className="field col-3 mt-3">
							<Button
								label="Add SAID"
								icon="pi pi-check"
								className="p-button-sm"
								loading={isICASaSAdding}
								disabled={said.length === 0}
								onClick={() => {
									console.log('Save Modal');
									clickAddICASaS();
								}}
							/>
						</div>
					</div>
				</div>

				<DataTable
					tableClassName='ica-sa'
					value={icaSaS}
					loading={isFetching}
					responsiveLayout="scroll"
				>
					<Column field="id" header="Id"></Column>
					<Column field="sAs" header="SAID" sortable></Column>

					<Column header="Delete" body={deleteKeyword}></Column>
				</DataTable>
			</Dialog>
			<Dialog
				header="Delete  SA Configuration"
				visible={deleteModal}
				onHide={() => {
					setDeleteModal(false);
				}}
				footer={deleteFooter}
			>
				<h5>Confirm SA Configuration Deletion</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<p>
							Do you really want to delete the SA Configuration ?
							<br />
							<br />
							Your action is irreversible !
						</p>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default SAConfiguration;
