import { useEffect, useMemo, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { useGetBugReportQuery, useUpdateBugStatusMutation } from '../../store/mailApi';
import {
	getSearchTerm,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import DisplayIcon from '../../components/icon';
import { Column } from 'primereact/column';
import { formatDateDDMMYYYY } from '../../utils/utility-function';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';

const BugReportTrack: React.FC = function () {

	const { data, isLoading, isSuccess, isError, error } = useGetBugReportQuery();

	const [updateBugStatus, { isLoading: isStatusUpdate }] = useUpdateBugStatusMutation();

	const [editStatus, setEditStatus] = useState<any>(undefined);

	const searchTerm = useSelector(getSearchTerm);

	const [searchKeyword, setSearchKeyword] = useState('');
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk: any) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: data;

		return result;
	}, [data, searchKeyword]);

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Bug Reports</h5>
				</div>
				{/*<div className="actions flex">
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Set SLst Alert"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							// dispatch(setICAExportModals({ slstArt: true }));
						}}
					>
						<DisplayIcon icon="tablerArrowSwap" className="action-icon" />
					</Button>
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Set SA Configuration"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							// dispatch(setICAExportModals({ saConfiguration: true }));
						}}
					>
						<DisplayIcon icon="settings" className="action-icon" />
					</Button>
				</div> */}
			</div>
		);
	};

	const statusList = [
		{ status: "Created", value: "Created" },
		{ status: "InProgress", value: "In Progress" },
		{ status: "Resolved", value: "Resolved" },
		{ status: "Closed", value: "Closed" },
	]

	const statusBody = (rowData: any) => {
		if (editStatus !== undefined && rowData.id === editStatus.id) {
			return (
				<Dropdown
					value={editStatus.status}
					options={statusList}
					onChange={(item) => {
						console.log(item)
						setEditStatus({ ...editStatus, status: item.value });
						console.log(editStatus)
					}}
					optionLabel="status"
					id="Status"
					name="Status"
				/>
			)
		} else {
			return rowData.status;
		}
	}

	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				{
					editStatus !== undefined && rowData.id === editStatus.id ? (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								loading={isStatusUpdate}
								onClick={async () => {
									// setModalType('ICA');
									console.log(editStatus);
									if (editStatus.status !== rowData.status) {
										await updateBugStatus({ trackingNumber: editStatus.trackingNumber, status: editStatus.status })
											.unwrap()
											.then((payload: any) => {
												toast.success('Status Updated Successfully')
												setEditStatus(undefined);
											})
											.catch((error: any) => toast.error(error.data.message));
									}
									// setEditStatus(rowData);
								}}
								tooltip="Save Status"
							>
								<DisplayIcon icon="tablerSave" className="table-action" />
							</Button>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									setEditStatus(undefined);
								}}
								tooltip="Cancel Update"
							>
								<DisplayIcon icon="tablerCancel" className="table-action" />
							</Button>

						</>
					) : (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									// setModalType('ICA');
									console.log(rowData);
									setEditStatus(rowData);
								}}
								tooltip="Edit Status"
							>
								<DisplayIcon icon="edit" className="table-action" />
							</Button>
						</>
					)
				}
			</div>
		);
	};

	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						tableClassName='bug-report-track'
						value={computedData}
						responsiveLayout="scroll"
						header={header}
						loading={isLoading}
					>
						<Column field="trackingNumber" header="Track Number" sortable></Column>
						<Column field="createdBy" header="Created By" sortable></Column>
						<Column field="subject" header="Subject" sortable></Column>
						<Column
							field='createdOn'
							header="Created On"
							body={(rowData) => formatDateDDMMYYYY(rowData.createdOn)}
							sortable
						></Column>
						<Column
							field='updatedOn'
							header="Updated On"
							body={(rowData) => formatDateDDMMYYYY(rowData.updatedOn)}
							sortable
						></Column>
						<Column field="status" header="Status" body={statusBody} sortable></Column>
						<Column
							body={actions}
							align="left"
							bodyStyle={{ width: '10%' }}
						></Column>
					</DataTable>
				</div>
			</div>
		</>
	)
}

export default BugReportTrack;