import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { useGetallnewassetQuery } from "../../store/apiSlice";
import { formatCurrency } from "../../utils/utility-function";

const NewAssets = () => {
	useEffect(() => {
		document.title = 'New Assets | XCharge';
	}, []);
    
	const { data, isFetching} = useGetallnewassetQuery();
    return (
        <>
            <div className={`grid`}>
				<div className="cards">
					<DataTable
						value={data}
						responsiveLayout="scroll"
						className='new-assets'
						dataKey="id"
						rowHover
                        header="New Assets"
                        loading={isFetching}
					>
						<Column field="id" header="ID" sortable></Column>
						<Column field="materialno" header="Materialnummer" sortable></Column>
						<Column field="asset" header="Beschreibung" sortable></Column>
						<Column field="price" header="EK-Preis" sortable body={(data) => formatCurrency(data.price)}></Column>
						<Column field="uploadMonth" header="Uploaded On" sortable 
							body={(data) => `${data.uploadMonth}-${data.uploadYear}`}>
						</Column>
						{/* <Column field="serviceName" header="Service Name" sortable></Column>
						<Column
							field="aditionalInfo"
							header="Additional Information"
							sortable
						></Column> */}
					</DataTable>
				</div>
			</div>
        </>
    )
}

export default NewAssets;