import { baseApi } from './baseApi';
import {
	IIONumberUpdateRequest,
	IIONumberUpdateServerRequest,
} from '../types/ionumber.interfaces';
import { IService } from '../types/service.interfaces';

const ioNumberApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		updateIONumber: builder.mutation<IService, IIONumberUpdateServerRequest>({
			query: ({ serviceList, ioNumber, cc_Id }) => ({
				url: `actuals/ionumbers`,
				method: 'POST',
				body: serviceList.map(
					({ serviceId, serviceName, said, additionalInfo }) => ({
						comment: ioNumber,
						serviceId,
						serviceName,
						said,
						additionalInformation: additionalInfo,
						versionId: 0,
						year: 0,
						cc_Id,
					})
				),
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
			],
		}),
		updateIONumberAzure: builder.mutation<
			IService,
			IIONumberUpdateServerRequest
		>({
			query: ({ cc_Id, serviceList, ioNumber }) => ({
				url: `azure/ionumbers`,
				method: 'POST',
				body: serviceList.map(({ serviceId, serviceName, said }) => ({
					comment: ioNumber,
					serviceId,
					serviceName,
					said,
					additionalInformation: 'null',
					versionId: 0,
					year: 0,
					cc_Id,
				})),
			}),
			invalidatesTags: (result, error, arg) => [
				{ type: 'Services', costCenterId: arg.cc_Id },
			],
		}),
	}),
});

export const { useUpdateIONumberMutation, useUpdateIONumberAzureMutation } =
	ioNumberApi;
