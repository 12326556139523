import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModal, settingsModalState } from '../../store/commonSlice';
import * as Yup from 'yup';
import InputField from '../../components/input-field/input-field';
import DisplayIcon from '../../components/icon';
import {
	useGetAllCountriesQuery,
	useGetAllDepartmentsQuery,
	useGetAllRegionsQuery,
} from '../../store/commonApi';
import { ICostCenterData } from '.';
import {
	formatDateDDMMYYYY,
	formatDateDDMMYYYYhhmm,
} from '../../utils/utility-function';
import { useAddCostCenterMutation } from '../../store/settingApi';
import { InputText } from 'primereact/inputtext';

const addMappingSchema = Yup.object().shape({
	serviceId: Yup.string().required('Required !'),
	serviceName: Yup.string().required('Required !'),
	said: Yup.string().required('Required !'),
	additionaInformation: Yup.string(),
	keyWord: Yup.string(),
	comments: Yup.string(),

	/* targetCostCenter: 
	targetResponsible: */
});

const AddCostCenter = ({
	mode,
	editData,
}: {
	mode?: any;
	editData?: ICostCenterData;
}) => {
	const dispatch = useDispatch();
	const openModal = useSelector(settingsModalState);
	const [formData, setFormData] = useState<any>({});

	const { data: allCountries = [] } = useGetAllCountriesQuery();
	const { data: allRegions = [] } = useGetAllRegionsQuery();
	const { data: allDepartments = [] } = useGetAllDepartmentsQuery();
	const [addCostCenter, { isLoading: isAddingCostCenter }] =
		useAddCostCenterMutation();

	const formik = useFormik({
		initialValues: {
			costCenter: mode === 'EDIT' ? editData!.target_costcenter : '',
			costCenterName: mode === 'EDIT' ? editData!.name : '',
			targetResponsible: mode === 'EDIT' ? editData!.target_responsible : '',
			active: mode === 'EDIT' ? editData!.isvisible : true,
			businessArea: mode === 'EDIT' ? editData?.region : '',
			country: mode === 'EDIT' ? editData!.country : '',
			department: mode === 'EDIT' ? editData?.department : '',
			requestedBy: mode === 'EDIT' ? editData!.requestedBy : '',
		},
		validationSchema: addMappingSchema,
		onSubmit: (data) => {
			setFormData(data);
			formik.resetForm();
		},
		validateOnBlur: true,
	});
	const renderFooter = (name: any) => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						formik.resetForm();
						dispatch(setSettingsModal({ addCostCenter: false }));
					}}
					className="p-button-text"
				/>
				<Button
				disabled={formik.values.costCenter.length === 0 || formik.values.costCenterName.length === 0 || formik.values.targetResponsible.length === 0}
					label="Save"
					icon="pi pi-check"
					onClick={
						() => {
							const id = mode === 'EDIT' ? editData!.id : 0;
							addCostCenter({
								countryName:
									typeof formik.values.country === 'object'
										? formik.values.country.name
										: 'Germany',
								departmentName:
									typeof formik.values.department === 'object'
										? 'null'
										: 'null',
								id: id,
								isVisible: formik.values.active,
								name: formik.values.costCenterName,
								regionName:
									typeof formik.values.businessArea === 'object'
										? 'null'
										: 'null',
								requestedBy: formik.values.requestedBy,
								target_costCenter: formik.values.costCenter,
								target_responsible: formik.values.targetResponsible,
							});
						}
						// formik.resetForm();
						// dispatch(setSettingsModal({ addCostCenter: false }));
					}
				/>
			</div>
		);
	};

	return (
		<Dialog
			header={
				mode && mode.toUpperCase() === 'EDIT'
					? 'Edit Cost Center'
					: 'Add Cost Center'
			}
			visible={openModal.addCostCenter}
			style={{ width: '50vw' }}
			footer={renderFooter('displayBasic')}
			onHide={() => {
				formik.resetForm();
				dispatch(setSettingsModal({ addCostCenter: false }));
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<h5>Cost Center</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<InputField
							field="costCenterName"
							formik={formik}
							label="Cost Center Name"
							type="input"
							colSpan={6}
						/>
						<InputField
							field="costCenter"
							formik={formik}
							label="Cost Center"
							colSpan={6}
						/>
						<InputField
							field="targetResponsible"
							formik={formik}
							label="ITM Target Responsible"
							colSpan={6}
						/>
						<div className="field col-2 flex align-items-center justify-content-evenly mt-3 ml-4">
							Active
							<InputSwitch
								id="active"
								className="ml-4"
								checked={formik.values.active}
								onChange={formik.handleChange}
							/>
						</div>
					</div>
				</div>
				<Divider />
				<h5>Meta</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<Dropdown
									value={formik.values.businessArea}
									options={allRegions}
									onChange={formik.handleChange}
									optionLabel="name"
									id="businessArea"
									name="businessArea"
								/>
								<label htmlFor="country">Business Area</label>
							</span>
						</div>
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<Dropdown
									value={formik.values.country}
									options={allCountries}
									onChange={formik.handleChange}
									optionLabel="name"
									id="country"
									name="country"
								/>
								<label htmlFor="country">Country</label>
							</span>
						</div>
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										editData?.valid_from
											? formatDateDDMMYYYY(editData?.valid_from)
											: formatDateDDMMYYYY(new Date().toISOString())
									}
									name="validFrom"
									disabled
								/>
								<label htmlFor="department">Valid From</label>
							</span>
						</div>

						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										editData?.valid_to
											? formatDateDDMMYYYY(editData?.valid_to)
											: formatDateDDMMYYYY(new Date().toISOString())
									}
									name="validTo"
									disabled
								/>
								<label htmlFor="validTo">Valid To</label>
							</span>
						</div>

						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<Dropdown
									value={formik.values.department}
									options={allDepartments}
									onChange={formik.handleChange}
									optionLabel="name"
									id="department"
									name="department"
								/>
								<label htmlFor="department">Department</label>
							</span>
						</div>
						<InputField
							field="requestedBy"
							formik={formik}
							label="Requested By"
							colSpan={6}
						/>
					</div>
				</div>
				<Divider />
				<h5>Updated</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										!editData?.updatedBy
											? '-'
											: editData?.updatedBy.firstName +
											  ' ' +
											  editData?.updatedBy.lastName +
											  ' (' +
											  editData?.updatedBy.email +
											  ')'
									}
									name="updatedBy"
									disabled
								/>
								<label htmlFor="department">Updated By</label>
							</span>
						</div>

						<div className="field col-6 mt-3">
							<span className="p-float-label">
								<InputText
									value={
										editData?.updatedOn
											? formatDateDDMMYYYYhhmm(editData.updatedOn)
											: formatDateDDMMYYYYhhmm(new Date().toISOString())
									}
									name="updatedBy"
									disabled
								/>
								<label htmlFor="department">Updated By</label>
							</span>
						</div>
					</div>
				</div>
			</form>
		</Dialog>
	);
};

export default AddCostCenter;
