// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-section {
  width: 100%;
  margin: 0;
  background-color: #fff;
  padding: 1rem 1rem;
  border-radius: 5px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.04), 0 7px 5px rgba(0, 0, 0, 0.02), 0 13px 10px rgba(0, 0, 0, 0.02), 0 22px 17px rgba(0, 0, 0, 0.02);
  background-color: hsla(0, 0%, 100%, 0.8);
  position: sticky;
  top: 0;
  z-index: 1000;
}
.search-section input {
  border: none;
  background: transparent;
}

.profile-info h5 {
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/search-bar/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,0IAAA;EAEA,wCAAA;EACA,gBAAA;EACA,MAAA;EACA,aAAA;AAAD;AACC;EACC,YAAA;EACA,uBAAA;AACF;;AAIC;EACC,iBAAA;AADF","sourcesContent":[".search-section {\n\twidth: 100%;\n\tmargin: 0;\n\tbackground-color: #fff;\n\tpadding: 1rem 1rem;\n\tborder-radius: 5px;\n\tbox-shadow: 0 3px 2px rgba(0, 0, 0, 0.04), 0 7px 5px rgba(0, 0, 0, 0.02),\n\t\t0 13px 10px rgba(0, 0, 0, 0.02), 0 22px 17px rgba(0, 0, 0, 0.02);\n\tbackground-color: hsl(0, 0%, 100%, 0.8);\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1000;\n\tinput {\n\t\tborder: none;\n\t\tbackground: transparent;\n\t}\n}\n\n.profile-info{\n\th5{\n\t\tfont-size: 1.2rem;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
