import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';

import Navigation from '../../components/navigation';

import { getAllCostCenter, allCostCenters } from '../../store/actualSlice';
import { selectSidebar } from '../../store/commonSlice';
import CostCenterCard from '../../components/cost-center-card';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { useGetAllActiveCostCentersQuery } from '../../store/apiSlice';
import { Outlet, useNavigate } from 'react-router-dom';

const ArchiveShellPage: React.FC = function () {
	//const allCostCenters = useAppSelector((state) => state.actual.actualAll);
	const sidebar = useSelector(selectSidebar);
	const dispatch = useAppDispatch();
	const {
		data = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetAllActiveCostCentersQuery();

	const navigate = useNavigate();

	return (
		<>
			<Outlet />
		</>
	);
};

export default ArchiveShellPage;
