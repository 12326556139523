import { OverlayPanel } from 'primereact/overlaypanel';
import { Icon } from '@iconify/react';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import { useDispatch, useSelector } from 'react-redux';
import ImageUpdater from '../image-updater/image-updater';
import {
	commonState,
	getCommonModal,
	getInvalidUser,
	getIsAdmin,
	getIsServiceOwner,
	getIsCostController,
	getSearchTerm,
	setCommonModal,
	setMailers,
	setSearchTerm,
} from '../../store/commonSlice';

import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import './style.scss';
import {
	useEnableMaintenanceMutation,
	useGetCurrentUserQuery,
	useIsNotifyUserMutation,
} from '../../store/UserApi';
import { useGetUserNotificationsQuery } from '../../store/notificationApi';
import CorrectionApproval from '../correction-approval';
import DisplayIcon from '../icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { current } from '@reduxjs/toolkit';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import Emailer from '../email/mailer';
import ServiceDifference from '../service-difference/service-difference';
import { useGetMappingUpdatesQuery } from '../../store/settingApi';
import MailAllUsers from '../email/email-all-users';
import { useGetMaintenanceModeQuery } from '../../store/commonApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { Message } from 'primereact/message';
import { useCookies } from 'react-cookie';
import AssignDeputy from '../../views/home/assign-deputy';
import Timeline from '../timeline';
import { useLocation } from 'react-router-dom';

const SearchBar = () => {
	const dispatch = useDispatch();
	const isAdmin = useSelector(getIsAdmin);
	const isServiceOwner = useSelector(getIsServiceOwner);
	const isInvalidUser = useSelector(getInvalidUser);
	const [updateIsNotifyUser, { isLoading: isUpdatingNotify }] =
		useIsNotifyUserMutation();
	const [updateMaintenance, { isLoading: isUpdatingMaintenance }] =
		useEnableMaintenanceMutation();
	const { data: isMaintenance } = useGetMaintenanceModeQuery();
	const searchTerm = useSelector(getSearchTerm);
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const commonModal = useSelector(getCommonModal);
	const [sendNotification, setSendNotification] = useState<any>();
	const [sendCloudCostsNotification, setSendCloudCostsNotification] = useState<any>();
	const [maintenanceMode, setMaintenanceMode] = useState<any>();
	useEffect(() => {
		setMaintenanceMode(isMaintenance === 'true' ? true : false);
	}, [isMaintenance]);
	const { data: currentUser } = useGetCurrentUserQuery(0);

	const [image, setImage] = useState<any>();
	useEffect(() => {
		setImage(currentUser?.photo);
		setSendNotification(currentUser?.isNotifyForNewChargingReportUpload);
		setSendCloudCostsNotification(currentUser?.isNotifyForNewCloudChargingReportUpload)
	}, [currentUser]);
	const {
		data: notifications,
		isLoading: userLoading,
		isSuccess,
		isError,
		error,
	} = useGetUserNotificationsQuery(currentUser?.id ?? skipToken);
	
	const path = useLocation();

	const { data: mappingUpdates = [] } = useGetMappingUpdatesQuery();

	const [query, setQuery] = useState('');
	useEffect(() => {
		const timeOutId = setTimeout(() => dispatch(setSearchTerm(query)), 500);
		return () => clearTimeout(timeOutId);
	}, [query]);

	const op = useRef<OverlayPanel>(null);

	const cropper = useCallback((node) => {
		console.log(node);

		if (node != null) {
			setImage(
				new Cropper(node, {
					aspectRatio: 1 / 1,
					viewMode: 2,
					dragMode: 'crop',
					autoCropArea: 1.0,
					minContainerHeight: 200,
					minContainerWidth: 200,
					background: true,
					rotatable: false,
					zoomOnWheel: false,
				})
			);
			console.log('setting crippoer');
		}
	}, []);

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

	const isCostController = useSelector(getIsCostController);

	return (
		isInvalidUser ? <></> :
		<div
			className={`search-section flex ${maintenanceMode && !isAdmin
				? 'justify-content-end'
				: 'justify-content-between'
				} align-items-center w-full mb-4`}
		>
			{!(maintenanceMode && !isAdmin) && (
				<div className="flex align-items-center">
					<Icon
						icon="fluent:search-20-regular"
						style={{ width: '2rem', height: '2rem', color: 'blue' }}
					></Icon>
					<InputText
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						placeholder="Search ..."
						className="w-25rem"
					/>
					{searchTerm.length > 0 && (
						<DisplayIcon
							className="clear-search"
							icon="tablerCancel"
							onClick={() => {
								setQuery('');
								dispatch(setSearchTerm(''));
							}}
						/>
					)}
				</div>
			)}

			{maintenanceMode && (
				<div className="mr-3">
					<Message
						severity="warn"
						content={
							<div className="flex align-items-center">
								<DisplayIcon icon="tablerBarrier" className="mr-3" />
								<span className="bold-text">Maintenane Mode Enabled</span>
								<DisplayIcon icon="tablerBarrier" className="ml-3" />
							</div>
						}
					></Message>
				</div>
			)}
			<div className="flex align-items-center">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					tooltip="Request Access"
					onClick={() => openInNewTab('https://alice.mercedes-benz.com/access')}
				>
					<DisplayIcon icon="requestAccess" className="searchbar-button" />
				</Button>
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					tooltip="XCharge Timeline"
					onClick={() => dispatch(setCommonModal({ timeline: true }))}
				>
					<DisplayIcon icon="timeline" className="searchbar-button" />
				</Button>
				{isAdmin && (
					<Button
						className="p-button-rounded p-button-text p-button-icon-only"
						tooltipOptions={{ position: 'left' }}
						tooltip="Email All Users"
						onClick={() => dispatch(setMailers({ allUsers: true }))}
					>
						<DisplayIcon icon="mail" className="searchbar-button" />
					</Button>
				)}
				{!(maintenanceMode && !isAdmin) && (
					<>
						<DisplayIcon
							icon="alert"
							className="p-overlay-badge searchbar-button correction-notification mr-3"
							onClick={() =>
								dispatch(setCommonModal({ correctionApproval: true }))
							}
							data-pr-tooltip="Display Received & Send Correction Request"
						>
							<div className="icon-badge">
								<Badge
									value={
										notifications
											? (
												notifications?.filter(
													(_notification) =>
														(_notification.requestedUId !== currentUser?.id &&
															_notification.ownerStatus.toLowerCase() !==
															'approved') ||
														_notification.reqUser ===
														`${currentUser?.lastName} ${currentUser?.firstName}`
												).length +
												notifications?.filter(
													(_notification) =>
														_notification.requestedUId === currentUser?.id
												).length
											).toString()
											: '0'
									}
								/>
							</div>
						</DisplayIcon>
						<Tooltip target=".correction-notification" position="top" />
					</>
				)}
				{!(maintenanceMode && !isAdmin) && (
					<>
						<DisplayIcon
							icon="warning"
							className="p-overlay-badge searchbar-button difference-notification mr-3"
							onClick={() =>
								dispatch(setCommonModal({ serviceDifference: true }))
							}
							data-pr-tooltip="Display Difference In Services"
							data-pr-position="top"
						>
							<div className="icon-badge">
								<Badge
									value={
										mappingUpdates?.length !== 0 ? mappingUpdates?.length : '0'
									}
								/>
							</div>
						</DisplayIcon>
						<Tooltip target=".difference-notification" />
					</>
				)}
				{currentUser?.photo.includes('Person-Avatar.png') ? (
					<Avatar
						data-pr-tooltip="See More User Settings & Information"
						label={`${currentUser.firstName.charAt(
							0
						)}${currentUser.lastName.charAt(0)}`}
						size="large"
						shape="circle"
						className="p-overlay-badge primary-text-bold avatar-icon"
						onClick={(e) => op.current?.toggle(e)}
					/>
				) : (
					<Avatar
						data-pr-tooltip="See More User Settings & Information"
						image={currentUser?.photo}
						size="large"
						shape="circle"
						className="p-overlay-badge avatar-icon"
						onClick={(e) => op.current?.toggle(e)}
					/>
				)}
				<Tooltip target=".avatar-icon" position="left" />

				<OverlayPanel
					ref={op}
					id="overlay_panel"
					style={{ width: 'max-content' }}
					className="profile-info flex"
				>
					<div className="box flex">
						<div
							className="image-container"
							onClick={() => dispatch(setCommonModal({ imageUpdater: true }))}
						>
							<img className="image-circle" src={image} alt="" />
							<div className="image-hover-option">
								{' '}
								<DisplayIcon icon="tablerEdit" className="reposition-icon" />
							</div>
						</div>
						<div className="profile-info flex ml-5 flex-column">
							<h5>{`${currentUser?.firstName} ${currentUser?.lastName}`}</h5>
							<h6>{`(${currentUser?.role.name.replace(/([A-Z])/g, ' $1').trim()})`}</h6>
							<h6 className="muted">Department</h6>
							<h5>{currentUser?.departmentNew}</h5>
							<h6 className="muted">Department Description</h6>
							<h5>{currentUser?.departmentDescription}</h5>
						</div>

						{/* <img className="w-full" src={image} alt="" ref={cropper} /> */}
					</div>

					<div className="options my-3">
						<div className="flex align-items-center my-3">
							Notify me when a new charge report is uploaded.{' '}
							<InputSwitch
								className="ml-4"
								checked={sendNotification}
								onChange={(e) => {
									setSendNotification(e.value);
									updateIsNotifyUser({
										'isNotifyForNewChargingReportUpload': e.value,
									});
								}}
							/>
						</div>
						{(isAdmin || isServiceOwner) &&<div className="flex align-items-center my-3">
							Notify me when a new Cloud costs report is uploaded.{' '}
							<InputSwitch
								className="ml-4"
								checked={sendCloudCostsNotification}
								onChange={(e) => {
									setSendCloudCostsNotification(e.value);
									updateIsNotifyUser({
										'isNotifyForNewCloudChargingReportUpload': e.value,
									});
								}}
							/>
						</div>}
						{isAdmin && (
							<div className="flex align-items-center my-3">
								Enable Maintenance Mode
								<InputSwitch
									className="ml-4"
									checked={maintenanceMode}
									onChange={(e) => {
										setMaintenanceMode(e.value);
										updateMaintenance(e.value);
									}}
								/>
							</div>
						)}
					</div>

					<div className="flex justify-content-between">
						{!isCostController &&
							<Button
								label="Assign Deputy"
								onClick={() => dispatch(setCommonModal({ assignDeputy: true }))}
							/>
						}
						<Button
							name="logout"
							label="Logout"
							onClick={() => {
								removeCookie('token');
								// eslint-disable-next-line no-restricted-globals
								location.href = process.env.REACT_APP_LOGOUT_URL as string;
							}}
						></Button>
					</div>
				</OverlayPanel>
			</div>
			{commonModal.correctionApproval && <CorrectionApproval />}
			{commonModal.serviceDifference && <ServiceDifference />}
			{commonModal.assignDeputy && <AssignDeputy />}
			{commonModal.timeline && <Timeline />}

			<MailAllUsers />
			{commonModal.imageUpdater && currentUser && (
				<ImageUpdater currentImage={image} id={currentUser.id}></ImageUpdater>
			)}
		</div>
	);
};

export default SearchBar;
