import { getIsAdmin, getIsServiceOwner, selectSidebar } from '../../../store/commonSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Icon } from '@iconify/react';

import './styles.scss';
import '../style.scss';
import {
	NavLink,
	useLocation,
	useMatch,
	useNavigate,
	useResolvedPath,
} from 'react-router-dom';
import DisplayIcon, { iconKey } from '../../icon';
import { Tooltip } from 'primereact/tooltip';

export interface IMenuItem {
	route: string;
	name: string;
	access: string;
	icon: iconKey;
	subItems: IMenuItem[] | [];
}

const MenuItem = ({ data }: { data: IMenuItem }) => {
	const sidebar = useSelector(selectSidebar);
	const [subMenu, setSubMenu] = useState('');
	const location = useLocation();
	const isAdmin: boolean = useSelector(getIsAdmin);
	const isServiceOwner: boolean = useSelector(getIsServiceOwner);
	const checkAccess = (access: string) => {
		if (isAdmin) {
			return true;
		} else if (access !== 'exceptServiceOwner' && access !== 'admin' && isServiceOwner) {
			return true;
		} else if (access !== 'admin' && access !== 'serviceOwner' && !isServiceOwner) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if(location.pathname.includes(data.route)) {
			setSubMenu(data.name)
		} else {
			setSubMenu('')
		}
	}, [location])
	
	return (
		<>
			{checkAccess(data.access) && (
				<li
					className={`animate-block w-full pop-menu`}
					data-pr-tooltip={data.name
						.split(' ')
						.map(
							(_item) =>
								_item.charAt(0).toLocaleUpperCase() + _item.substring(1)
						)
						.join(' ')}
				>
					<NavLink
						onClick={(e) => {
							if(sidebar && data.subItems.length > 0 && subMenu.length === 0) {
								setSubMenu(data.name)
								e.preventDefault();
							} 
							else if(sidebar && data.subItems.length > 0 && subMenu.length !== 0) {
								setSubMenu('')
								e.preventDefault();
							}
						}}
						style={({ isActive }) => {
							return { color: isActive ? '#0049f5' : '#667085' };
						}}
						className={({ isActive }) => {
							return `flex align-items-center w-full  ${
								isActive ? 'icon-primary-color' : 'icon-secondary-color'
							}`;
						}}
						to={`/${data.route}`}
						state={{ prevPath: location.pathname }}
					>
						<div className="flex align-items-center justify-content-between w-full">
							<div className="flex align-items-center">
								<DisplayIcon
									icon={data.icon}
									className={`flex mr-2 ${
										data.icon === 'circle' ? 'subMenuIcon' : 'menuIcon'
									}`}
								/>

								{sidebar && (
									<span style={{ textTransform: 'capitalize' }}>
										{data.name}
									</span>
								)}
							</div>

							{sidebar && data.subItems.length > 0 && subMenu.length === 0 && (
								<DisplayIcon
									onClick={(e) => {
										e.preventDefault();
										setSubMenu(data.name);
									}}
									icon="expand"
									className="flex expand"
								/>
							)}

							{sidebar && data.subItems.length > 0 && subMenu.length !== 0 && (
								<DisplayIcon
									onClick={(e) => {
										e.preventDefault();
										setSubMenu('');
									}}
									icon="collapse"
									className="flex expand"
								/>
							)}
						</div>
					</NavLink>
				</li>
			)}
			{subMenu === data.name && (
				<ul className="child-menu">
					{data.subItems.length > 0 &&
						data.subItems
							.map((subItem) => ({
								...subItem,
								route: `${data.route}/${subItem.route}`,
							}))
							.map((subItem) => <MenuItem key={subItem.name} data={subItem} />)}
				</ul>
			)}
			<Tooltip target=".pop-menu" />
		</>
	);
};

export default MenuItem;
