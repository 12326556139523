import Menu from './menu/Menu';

import { useSelector, useDispatch } from 'react-redux';
import {
	toggleSidebar,
	selectSidebar,
	getIsAdmin,
	setMailers,
	getMailers,
	getIsCostController,
} from '../../store/commonSlice';
import { useLocation, useNavigate, useParams, Navigate } from 'react-router-dom';
import { Menu as PrimeMenu } from 'primereact/menu';
import './style.scss';
import DisplayIcon from '../icon';
import { Divider } from 'primereact/divider';
import { useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import MailBugReport from '../email/email-bug-report';

const Navigation: React.FC = function () {
	const menu = useRef<PrimeMenu>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const sidebar = useSelector(selectSidebar);
	const dispatch = useDispatch();
	const isAdmin = useSelector(getIsAdmin);
	const isCostController = useSelector(getIsCostController);
	const mailers = useSelector(getMailers);

	const items = isAdmin
		? [
			{
				label: 'Download Manual',
				items: [
					{
						label: 'Admin Manual',
						url: '/XCharge_AdminManual_v4.docx',
						target: '_blank',
					},

					{
						label: 'User Manual',
						url: '/XCharge_Manual_v8.docx',
						target: '_blank',
					},
					{
						label: 'Cost Controller Manual',
						url: '/XCharge_Cost_Controller_Manual_v2.docx',
						target: '_blank',
					},
				],
			},
		]
		: (isCostController ?
			[
				{
					label: 'Download Manual',
					items: [
						{
							label: 'Cost Controller Manual',
							url: '/XCharge_Cost_Controller_Manual_v2.docx',
							target: '_blank',
						},
					],
				},
			] :
			[
				{
					label: 'Download Manual',
					items: [
						{
							label: 'User Manual',
							url: '/XCharge_Manual_v8.docx',
							target: '_blank',
						}
					],
				},
			]
		);
	return (
		location.pathname === "/invalidUser" ? <></> :
		<aside
			className={`transition-all transition-linear transition-duration-300  justify-content-start ${sidebar ? 'lg:w-auto' : 'lg:w-auto'
				} lg:block hidden mt-3`}
		>
			{sidebar ? (
				<DisplayIcon
					className="menu-toggle"
					icon="toggleMenu"
					onClick={() => dispatch(toggleSidebar())}
				/>
			) : (
				<DisplayIcon
					className="menu-toggle"
					icon="toggleMenu"
					onClick={() => dispatch(toggleSidebar())}
				/>
			)}

			<div className="flex flex-column justify-content-start h-full align-items-center">
				<div className="flex flex-column justify-content-center align-items-center justify-content-between">
					<div
						id={`${process.env.REACT_APP_ENV === "integration" ? "logo-int" : "logo"}`}
						className="border-bottom flex align-items-center justify-content-center pb-3 mb-4"
						style={{ cursor: 'pointer' }}
						onClick={() => navigate('/')}
					>
						<DisplayIcon icon="tablerLogo" className={`${process.env.REACT_APP_ENV === "integration" ? "main-logo-int" : "main-logo"}`} />
						{sidebar && <h1>XCharge</h1>}
					</div>
					<div id="menu" className={`${sidebar ? 'w-18rem' : 'w-auto'}`}>
						<Menu />
					</div>
				</div>
				<div className="make-border mb-4"></div>
				<div
					className={`flex flex-column  ${sidebar ? 'justiy-content-start' : 'align-items-center mr-3'
						} w-full`}
				>
					<PrimeMenu model={items} id="popup_menu" popup ref={menu} />
					<div
						id="notifications"
						className="extra-menu-options menu-helper"
						onClick={(event) => {
							if (menu !== null && menu.current !== null) {
								menu.current.toggle(event);
							}
						}}
						data-pr-tooltip="Download User Manual"
						aria-controls="popup_menu"
						aria-haspopup
						style={{ cursor: 'pointer' }}
					>
						<DisplayIcon icon="tablerBook" className="menu-icon" />
						{sidebar && <span>User Manual</span>}
					</div>
					<div
						id="bugReport"
						className="extra-menu-options menu-helper"
						onClick={() => {
							dispatch(setMailers({ bugReportMailer: true, minimizeBugReport: false }));
							console.log(mailers.minimizeData, 'minimized data =----------')
						}}
						data-pr-tooltip="Report Bug"
						style={{ cursor: 'pointer', paddingTop: '0.5em' }}
					>
						<DisplayIcon icon="bugReport" className="menu-icon" />
						{sidebar && <span>Report Bug</span>}
					</div>
					<div
						id="bugReportTrack"
						className="extra-menu-options menu-helper"
						onClick={() => navigate('/bugTrack', { state: { prevPath: location.pathname } })}
						data-pr-tooltip="Bug Report Tracker"
						style={{ cursor: 'pointer', paddingTop: '0.5em' }}
					>
						<DisplayIcon icon="reportTrack" className="menu-icon" />
						{sidebar && <span>Bug Report Tracker</span>}
					</div>
					<div
						id="releaseNotes"
						className="extra-menu-options menu-helper"
						onClick={() => navigate('/releaseNotes', { state: { prevPath: location.pathname } })}
						data-pr-tooltip="Release Notes"
						style={{ cursor: 'pointer', paddingTop: '0.5em' }}
					>
						<DisplayIcon icon="releaseNote" className="menu-icon" />
						{sidebar && <span>Release Notes</span>}
					</div>
				</div>
			</div>
			<Tooltip target=".menu-helper" />

			<MailBugReport
				defaultTo={["xcharge@mercedes-benz.com"]}
				defaultCC={["ibm.a.kumar@mercedes-benz.com", "qburst.mathew@mercedes-benz.com"]}
				showAttachment={true}
				subjectPlaceholder="Issue / Improvement XCharge / *Description*"
				EditorPlaceHolder={`Short Description: #Please add a short description.
Description: #Please describe the issues as detailed as possible and add some steps to reproduce if possible`
				}
			/>
		</aside>
	);
};

export default Navigation;
