import { Icon } from '@iconify/react';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useRef, useState } from 'react';
import DisplayIcon from '../icon';
import './style.scss';

const FileUploader = ({
	callback,
	disabled,
}: {
	callback: (file: File | undefined) => any;
	disabled?: boolean;
}) => {
	const filePicker = useRef<HTMLInputElement>(null);
	const [selectedFile, setSelectedFile] = useState<File>();
	function onChange() {
		if (filePicker.current !== null && filePicker.current.files !== null) {
			console.log(filePicker.current.files[0]);
			setSelectedFile(filePicker.current.files[0]);
		}
	}
	useEffect(() => {
		if (callback) callback(selectedFile);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFile]);

	return (
		<>
			{disabled === true ? (
				<div className="flex flex-column field col-12 mt-3">
					<Skeleton height="15rem" className="w-full" />
				</div>
			) : (
				<>
					<div className="flex flex-column field col-12 mt-3">
						<div
							className="flex flex-column align-items-center w-full uploader"
							onDrop={(e) => {
								console.log('DROP....');
								e.preventDefault();
								document
									.querySelector('.upload-icon')
									?.classList.add('upload-icon-hidden');
								document
									.querySelector('.upload-shadow')
									?.classList.add('upload-shadow-hidden');
								document
									.querySelector('.upload-ok')
									?.classList.remove('upload-ok-hidden');
								document
									.querySelector('.uploader')
									?.classList.remove('gray-hue');
								document.querySelector('.uploader')?.classList.add('green-hue');
								setSelectedFile(e.dataTransfer.files[0]);
							}}
							onDragOver={(e) => {
								e.preventDefault();
							}}
							onDragEnter={(e) => {
								console.log('ENTER....');
								document.querySelector('.uploader')?.classList.add('gray-hue');
								if (selectedFile === undefined) {
									document
										.querySelector('.upload-icon')
										?.classList.add('upload-icon-hidden');
									document
										.querySelector('.upload-shadow')
										?.classList.remove('upload-shadow-hidden');
								} else {
									document
										.querySelector('.upload-ok')
										?.classList.add('upload-ok-hidden');
									document
										.querySelector('.upload-shadow')
										?.classList.remove('upload-shadow-hidden');
								}
							}}
							onDragLeave={(e) => {
								console.log('EXIT....');
								document
									.querySelector('.uploader')
									?.classList.remove('gray-hue');
								if (selectedFile === undefined) {
									document
										.querySelector('.upload-shadow')
										?.classList.add('upload-shadow-hidden');
									document
										.querySelector('.upload-icon')
										?.classList.remove('upload-icon-hidden');
								} else {
									document
										.querySelector('.upload-ok')
										?.classList.remove('upload-ok-hidden');
									document
										.querySelector('.upload-shadow')
										?.classList.add('upload-shadow-hidden');
								}
							}}
						>
							<input
								type="file"
								name=""
								id="upload"
								ref={filePicker}
								onChange={onChange}
							/>
							<label
								htmlFor="upload"
								id="open-uploader"
								className="flex flex-column align-items-center"
							>
								<DisplayIcon icon="documentAdd" className="upload-icon" />
								<DisplayIcon
									icon="documentShadow"
									className="upload-shadow upload-shadow-hidden"
								/>
								<DisplayIcon
									icon="documentOk"
									className="upload-ok upload-ok-hidden"
								/>

								<h4 className="mt-6">
									Drag and drop or <span>browse</span> your file
								</h4>
							</label>
						</div>
					</div>

					{selectedFile ? (
						<div className="uploaded flex justify-content-between field col-4">
							<div className="flex justify-content-between">
								<div className="flex align-items-center justify-content-arround">
									<Icon icon="ph:file-xls-thin"></Icon>
									<span
										className="ml-2 mr-3 filename"
										style={{
											maxWidth: '175px',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
										}}
									>
										{selectedFile.name}
									</span>
								</div>
								<DisplayIcon
									icon="dismiss"
									onClick={() => {
										document
											.querySelector('.upload-icon')
											?.classList.remove('upload-icon-hidden');
										document
											.querySelector('.upload-ok')
											?.classList.add('upload-ok-hidden');
										document
											.querySelector('.uploader')
											?.classList.remove('green-hue');
										setSelectedFile(undefined);
									}}
									className="dismiss-file red-icon"
								/>
							</div>
						</div>
					) : (
						<span className="field col-4 placeholder-text mt-3">
							Selected File ....
						</span>
					)}
				</>
			)}
		</>
	);
};

export default FileUploader;
