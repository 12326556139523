import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	setDataProcessingModal,
	setSettingsModal,
	settingsModalState,
} from '../../store/commonSlice';
import FileUploader from '../../components/file-uploader';
import { Dropdown } from 'primereact/dropdown';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { Months } from '../../utils/json/months';
import {
	useUploadChargingReportMutation,
	useUploadCostcentersMutation,
} from '../../store/chargingReportApi';
import { Form } from 'formik';
import { toast } from 'react-toastify';

const CostCenterUpload = () => {
	const dispatch = useDispatch();

	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedMonth, setSelectedMonth] = useState();
	const openModal = useSelector(settingsModalState);

	const [uploadCostCenters, { isLoading: isUploading }] =
		useUploadCostcentersMutation();
	const footer = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => dispatch(setSettingsModal({ costCenterUpload: false }))}
			/>
			<Button
				label="Upload "
				icon="pi pi-check"
				disabled={selectedFile === undefined}
				onClick={async () => {
					console.log(selectedFile);
					//dispatch(setDataProcessingModal({ uploadChargingReport: false }))
					if (selectedFile) {
						const payload = new FormData();
						payload.append('importdata', selectedFile);
						await uploadCostCenters({
							file: payload,
						})
							.unwrap()
							.then((payoad: any) =>
								toast.success('Uploaded Cost Center Report Successfully')
							)
							.catch((e: any) => {
								toast.error(e.data.message);
							});

						dispatch(setSettingsModal({ costCenterUpload: false }));
					}
				}}
			/>
		</div>
	);

	const fileCallback = (file: File | undefined) => {
		setSelectedFile(file);
	};

	return (
		<Dialog
			header="Upload Cost Center Report"
			visible={openModal.costCenterUpload}
			style={{ width: '50vw' }}
			footer={footer}
			onHide={() => dispatch(setSettingsModal({ costCenterUpload: false }))}
		>
			<div className="grid p-fluid">
				<div className="grid col-12">
					<FileUploader callback={fileCallback} />
				</div>
			</div>
		</Dialog>
	);
};

export default CostCenterUpload;
