import { useEffect, useMemo, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../views/actuals/styles.scss';
import { useGetAzureChargingReportQuery } from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
	dataProcessingModalState,
	getSearchTerm,
	setDataProcessingModal,
} from '../../store/commonSlice';
import UploadAzureReport from './upload-azure-report';
import { formatDateDDMMYYYY } from '../../utils/utility-function';
import useDownloadFile from '../../hooks/useDownloadFile';
import { useDeleteAzureReportMutation, useSendCRUploadNotificationMutation } from '../../store/chargingReportApi';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const AzureChargingReport: React.FC = function () {
	const searchTerm = useSelector(getSearchTerm);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [filter, setFilter] = useState('');
	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);
	const { data, isLoading, isSuccess, isError, error, isFetching } =
		useGetAzureChargingReportQuery();

	const [deleteAzureReport, { isLoading: isDeleting }] =
		useDeleteAzureReportMutation();

	const { downloadFile } = useDownloadFile();

	const [sendCRUploadNotification, { isLoading: isSending }] = useSendCRUploadNotificationMutation();
	function onRowclick(data: any) {
		console.log(data);
	}

	const [showNotificationDialog, setShowNotificationDialog] = useState(false);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: data;

		if (filter !== '') {
			console.log('Checking for filter ::', filter);
			result = result?.filter((_result) =>
				_result.uploadedMonthYear.includes(filter)
			);
		}

		return result;
	}, [data, searchKeyword, filter]);

	useEffect(() => {
		document.title = 'Cloud Costs Charging Report | XCharge';
	}, []);
	const openModal = useSelector(dataProcessingModalState);

	const AzureReportHeader = () => {
		const dispatch = useDispatch();

		return (
			<>
				<div className="flex flex-column justify-content-between align-items-center">
					<div className="flex justify-content-between w-full align-items-center">
						<div className="flex align-items-baseline w-full">
							<h5>Cloud Costs Charging Report</h5>
						</div>

						<div className="actions flex align-items-center justify-content-between mr-4">
							<div className="app-icon mr-2">
								<DisplayIcon icon="filter" />
							</div>
							<CustomAutoComplete
								customId="filter-active"
								placeholder="Filter By Year"
								inputList={[
									{ value: '2023', key: '2023' },
									{ value: '2022', key: '2022' },
									{ value: '2021', key: '2021' },
									{ value: '2020', key: '2020' },
								]}
								field="key"
								target="value"
								callback={(filter: any) => {
									filter ? setFilter(filter.value) : setFilter('');
								}}
							/>
						</div>

						<div className="flex align-items-center justify-content-end icon-section">
							<div className="flex flex-columns">
								<div className="flex">
									<div className="actions flex ml-4">
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Upload Cloud Costs Charging Report"
											tooltipOptions={{ position: 'left' }}
											onClick={() =>
												dispatch(
													setDataProcessingModal({
														uploadAzureChargingReport: true,
													})
												)
											}
										>
											<DisplayIcon icon="upload" className="action-icon" />
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
	const actions = (rowData: any, props: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						downloadFile(
							`azure/exportfile/${rowData.id}`,
							`CloudCosts-Charging-Report_${new Date().toISOString()}.xlsx`
						);
					}}
					tooltip="Export Cloud Costs Charging Report"
				>
					<DisplayIcon icon="tablerFileExport" className="table-action" />
				</Button>
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						await deleteAzureReport(rowData.id)
							.unwrap()
							.then((payoad: any) =>
								toast.success('Deleted Cloud Costs Charging Report Successfully')
							)
							.catch((e: any) => {
								toast.error(e.data.message);
							});
					}}
					tooltip="Delete Cloud Costs Charging Report"
				>
					<DisplayIcon icon="tablerTrash" className="table-action" />
				</Button>
				{ props.rowIndex === 0 && 
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'right' }}
					onClick={async () => {
						setShowNotificationDialog(true);
						console.log(rowData);
						
					}}
					tooltip="Send Notification Mail"
				>
					<DisplayIcon icon="tablerMail" className="table-action" />
				</Button>
				}
			</div>
		);
	};

	const NotificationFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => setShowNotificationDialog(false)}
			/>
			<Button
				label="Send"
				icon="pi pi-check"
				loading={isSending}
				onClick={async () => {
					// process.env.REACT_APP_ENV === 'production' && 
					sendCRUploadNotification({ uploadType: 'CloudCosts' }).unwrap()
						.then((payoad: any) => {
							toast.success('Notification sent Successfully')
							setShowNotificationDialog(false);
						}
						).catch((e: any) => {
							toast.error(e.data.message);
						});
				}}
			/>
		</div>
	);

	return (
		<div className={`grid`}>
			<div className="cards">
				<DataTable
					tableClassName='azure-reports'
					value={computedData}
					responsiveLayout="scroll"
					onRowClick={(event) => onRowclick(event)}
					header={AzureReportHeader}
					loading={isFetching}
				>
					<Column
						header="#"
						alignHeader="left"
						body={(data, props) => props.rowIndex + 1}
					></Column>
					<Column
						field="uploadedMonthYear"
						header="Month Of Upload"
						sortable
					></Column>
					<Column
						field="uploadedOn"
						header="Upload Date"
						body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
						sortable
					></Column>
					<Column
						field="uploadedBy.firstName"
						header="Uploader"
						body={(data) =>
							`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
						}
						sortable
					/>
					<Column field="status" header="Status" sortable></Column>
					<Column body={actions} align="center"></Column>
				</DataTable>
				<Dialog
					header="Send Notification Maill"
					visible={showNotificationDialog}
					footer={NotificationFooter}
					onHide={() => setShowNotificationDialog(false)}
				>
					<p className="m-2 px-5 bold-text">
						Do you want to send notification mail for the latest Cloud costs report upload?
					</p>
				</Dialog>
			</div>
			{openModal.uploadAzureChargingReport && <UploadAzureReport />}
		</div>
	);
};

export default AzureChargingReport;
