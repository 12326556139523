import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice, {
	commonState,
	getCommonModal,
	setCommonModal,
	setMailers,
} from '../../store/commonSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetUserNotificationsQuery } from '../../store/notificationApi';
import { useGetCurrentUserQuery } from '../../store/UserApi';
import DisplayIcon from '../icon';
import { useMemo, useState } from 'react';
import { current } from '@reduxjs/toolkit';
import { Button } from 'primereact/button';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';
import {
	useReassignServiceMutation,
	useRejectServiceMutation,
} from '../../store/servicesApi';
import { selectedCurrentUser } from '../../store/selectors/current-user';
import MailITTResponsible from '../email/email-correction-rejection';
import { formatCurrency } from '../../utils/utility-function';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export interface ICorrectionItem {
	id: number;
	sourceccId: number;
	sourcecc: string;
	sourceRes: string;
	targetccId: number;
	targetcc: string;
	targetRes: string;
	serviceId: string;
	serviceName: string;
	said: string;
	additionalInformation: string;
	requestedUId: number;
	reqUser: string;
	months: string;
	planned: number;
	booked: number;
	responsible: string;
	isApproved: boolean;
	reqStatus: string;
	ownerStatus: string;
	deputy: string;
}
const CorrectionApproval = () => {
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const [reAssignService, { isLoading: isUpdating }] =
		useReassignServiceMutation();

	const [rejecetService, { isLoading: isRejected }] =
		useRejectServiceMutation();
	const {
		data: allCostCenterList = [],
		isLoading: isListLoading,
		isSuccess: isListSuccess,
		isError: isListError,
		error: listError,
	} = useGetAllCostCentersQuery();

	const getCostCenterDetails = (id: number | undefined) =>
		id
			? allCostCenterList.find((_costCenter) => _costCenter.id === id)
			: undefined;

	const approvalDataConvertToMonthArray = (months: string) => {
		const MONTH_ARRAY = [
			'jan',
			'feb',
			'mar',
			'apr',
			'may',
			'jun',
			'jul',
			'aug',
			'sep',
			'oct',
			'nov',
			'dec',
		];
		const monthIndex = months
			.split(',')
			.map(
				(month) => MONTH_ARRAY.findIndex((item) => item === month.trim()) + 1
			);
		return monthIndex;
	};
	const [showApprovalDialog, setShowApprovalDialog] = useState(false);

	const [showRejectDialog, setShowRejectDialog] = useState(false);
	const [rejectionComments, setRejectionComments] = useState('');

	const {
		data: notifications,
		isLoading: notificationsLoading,
		isSuccess,
		isError,
		error,
	} = useGetUserNotificationsQuery(currentUser?.id ?? skipToken);
	const [selectedItem, setSelectedItem] = useState<ICorrectionItem>();

	const RejectionFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => setShowRejectDialog(false)}
			/>
			<Button
				label="Reject"
				icon="pi pi-check"
				disabled={rejectionComments.length === 0}
				onClick={async () => {
					if (selectedItem) {
						const payload = {
							comment: selectedItem.sourceccId,
							serviceId: selectedItem.serviceId,
							serviceName: selectedItem.serviceName,
							said: selectedItem.said,
							additionalInformation: selectedItem.additionalInformation,
							versionId: undefined,
							year: undefined,
							cc_Id: selectedItem.targetccId,
							months: approvalDataConvertToMonthArray(selectedItem?.months),
							isOwner: false,
							cC_NTID: getCostCenterDetails(selectedItem?.targetccId)
								? getCostCenterDetails(selectedItem?.targetccId)
										?.user_responsible
								: '',
							reqUid: selectedItem.requestedUId || 0,
							isActuals: false,
							planned: selectedItem.planned,
							booked: selectedItem.booked,
							responsible: selectedItem.responsible,
						};

						console.log('Payload for Rejection :: ', payload);
						await rejecetService({
							...payload,
							reason: rejectionComments,
						})
							.unwrap()
							.then((payload: any) =>
								toast.success('Re-Assignment Rejected Successfully.')
							)
							.catch((error: any) =>
								toast.error('Re-Assignment Rejection Failed.')
							);
						setShowRejectDialog(false);
					}
				}}
			/>
		</div>
	);
	const ApprovalFooter = () => (
		<div>
			<Button
				label="Cancel"
				icon="pi pi-times"
				onClick={() => setShowApprovalDialog(false)}
			/>
			<Button
				label="Approve"
				icon="pi pi-check"
				onClick={async () => {
					if (selectedItem) {
						const payload = {
							comment: selectedItem.sourceccId,
							serviceId: selectedItem.serviceId,
							serviceName: selectedItem.serviceName,
							said: selectedItem.said,
							additionalInformation: selectedItem.additionalInformation,
							versionId: undefined,
							year: undefined,
							cc_Id: selectedItem?.targetccId,
							months: approvalDataConvertToMonthArray(selectedItem!.months),
							isOwner: false,
							cC_NTID: getCostCenterDetails(selectedItem.targetccId)
								? getCostCenterDetails(selectedItem.targetccId)
										?.user_responsible
								: '',
							reqUid: selectedItem?.requestedUId || 0,
							isActuals: false,
							planned: selectedItem?.planned,
							booked: selectedItem?.booked,
							responsible: selectedItem?.responsible,
							isUser: false,
							isDeputy: currentUser!.isDeputy,
							sourceCC: '',
							targetCC: '',
							source_NTID: getCostCenterDetails(selectedItem.sourceccId)
								? getCostCenterDetails(selectedItem.sourceccId)
										?.user_responsible
								: '',
							ioNumber: '',
							isAdmin: currentUser!.role.isAdmin,
							isSuperAdmin: currentUser!.role.isSuperAdmin,
						};

						console.log('Payload for correction approval :: ', payload);
						await reAssignService(payload)
							.unwrap()
							.then((payload: any) =>
								toast.success('Re-Assignment Approved Successfully.')
							)
							.catch((error: any) =>
								toast.error('Re-Assignment Approval Failed.')
							);
						setShowApprovalDialog(false);
					}
				}}
			/>
		</div>
	);
	const receivedNotifications = useMemo(() => {
		return notifications?.filter(
			(_notification) =>
				((_notification.requestedUId !== currentUser?.id ||
					_notification.deputy === currentUser.ntid) &&
					_notification.ownerStatus.toLowerCase() !== 'approved') ||
				_notification.reqUser ===
					`${currentUser?.lastName} ${currentUser?.firstName}`
		);
	}, [notifications, currentUser]);
	const sendNotifications = useMemo(() => {
		return notifications?.filter(
			(_notification) => _notification.requestedUId === currentUser?.id
		);
	}, [notifications, currentUser]);

	const dispatch = useDispatch();
	const commonModal = useSelector(getCommonModal);
	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Received Notifications</h5>
				<div className="actions flex"></div>
			</div>
		);
	};
	const header2 = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Send Notifications</h5>
				<div className="actions flex"></div>
			</div>
		);
	};
	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				<DisplayIcon
					icon="tablerCheck"
					className="table-action-rounded"
					onClick={() => {
						setSelectedItem(rowData);
						console.log('SELECTED DATA FOR CORRECTION', rowData);
						setShowApprovalDialog(true);
					}}
				/>
				<DisplayIcon
					icon="tablerCancel"
					className="table-action-rounded"
					onClick={() => {
						setSelectedItem(rowData);
						console.log('SELECTED DATA FOR CORRECTION', rowData);
						setShowRejectDialog(true);
					}}
				/>
			</div>
		);
	};

	const capitalizeMonths = (months: any) => {
		return months
			? months
					.split(',')
					.map(
						(month: any) =>
							month.trim().charAt(0).toUpperCase() +
							month.trim().substring(1, month.trim().length)
					)
					.join(', ')
			: '';
	};
	const mailerDataMessage = (selectedItem: any, currentUser: any) => `Hi,

Please update the reassigned cost center for the service details mentioned below in the ITT service tool.

The reassignment of the service from ${selectedItem.sourcecc} to ${
		selectedItem.targetcc
	}, which has been sent for the approval and is approved by ${
		selectedItem.targetRes
	}
ServiceID = ${selectedItem.serviceId}
ServiceName = ${selectedItem.serviceName}
Said = ${selectedItem.said}
AdditionalInformation = ${selectedItem.additionalInformation}
Months = ${capitalizeMonths(selectedItem.months)}
Planned Amount = ${selectedItem.planned} €
	
Thanks & Regards,
${currentUser.firstName} ${currentUser.lastName}
		  `;

	const actions2 = (rowData: any) => {
		return (
			<div className="table-menu">
				{rowData.reqStatus === 'Approved' && (
					<DisplayIcon
						icon="tablerMail"
						className="table-action-rounded"
						onClick={() => {
							setSelectedItem(rowData);
							dispatch(setMailers({ ittResponsible: true }));
						}}
					/>
				)}
			</div>
		);
	};
	return (
		<>
			<Dialog
				header="Correction Approvals"
				visible={commonModal.correctionApproval}
				style={{ width: '100vw' }}
				onHide={() => dispatch(setCommonModal({ correctionApproval: false }))}
			>
				<div className={`grid`}>
					<div className="cards">
						<DataTable
							loading={notificationsLoading}
							value={receivedNotifications}
							responsiveLayout="scroll"
							header={header}
						>
							<Column field="sourcecc" header="Old Cost Center"></Column>
							<Column
								field="sourceRes"
								header="Old Cost Center Owner"
								sortable
							></Column>
							<Column
								field="targetcc"
								header="New Cost Center"
								sortable
							></Column>
							<Column field="serviceId" header="Service Id" sortable></Column>
							<Column
								field="serviceName"
								header="Service Name"
								sortable
							></Column>
							<Column
								header="Months"
								body={(data) => capitalizeMonths(data.months)}
							></Column>
							<Column
								field="planned"
								header="Planned"
								body={(data) => formatCurrency(data.planned)}
								sortable
							></Column>
							<Column
								field="booked"
								header="Booked"
								body={(data) => formatCurrency(data.booked)}
								sortable
							></Column>
							<Column
								field="additionalInformation"
								header="Additional Info"
								sortable
							></Column>
							<Column field="reqStatus" header="Status" sortable></Column>
							<Column
								body={actions}
								align="center"
								bodyStyle={{ width: '10%' }}
							></Column>
						</DataTable>
					</div>
				</div>

				<div className={`grid mt-8`}>
					<div className="cards">
						<DataTable
							loading={notificationsLoading}
							value={sendNotifications}
							responsiveLayout="scroll"
							header={header2}
						>
							<Column field="sourcecc" header="Old Cost Center"></Column>
							<Column
								field="sourceRes"
								header="Old Cost Center Owner"
								sortable
							></Column>
							<Column
								field="targetcc"
								header="New Cost Center"
								sortable
							></Column>
							<Column field="serviceId" header="Service Id" sortable></Column>
							<Column
								field="serviceName"
								header="Service Name"
								sortable
							></Column>
							<Column
								header="Months"
								body={(data) => capitalizeMonths(data.months)}
								sortable
							></Column>
							<Column
								field="planned"
								header="Planned"
								body={(data) => formatCurrency(data.planned)}
								sortable
							></Column>
							<Column
								field="booked"
								header="Booked"
								body={(data) => formatCurrency(data.booked)}
								sortable
							></Column>
							<Column
								field="additionalInformation"
								header="Additional Info"
								sortable
							></Column>
							<Column field="reqStatus" header="Status" sortable></Column>
							<Column
								body={actions2}
								align="center"
								bodyStyle={{ width: '10%' }}
							></Column>
						</DataTable>
					</div>
				</div>
			</Dialog>

			<Dialog
				header="Approve Correction Request"
				visible={showApprovalDialog}
				footer={ApprovalFooter}
				onHide={() => setShowApprovalDialog(false)}
			>
				<p className="mt-5 px-5 bold-text">
					Please Confirm transfer of Service{' '}
					<span className="bold-text">{selectedItem?.serviceName}</span> for
					months{' '}
					<span className="bold-text">
						{capitalizeMonths(selectedItem?.months)}
					</span>{' '}
					from{' '}
					<span className="bold-text">
						{selectedItem?.sourcecc} -{' '}
						{getCostCenterDetails(selectedItem?.sourceccId)?.name}
					</span>{' '}
					to{' '}
					<span className="bold-text">
						{selectedItem?.targetcc} -{' '}
						{getCostCenterDetails(selectedItem?.targetccId)?.name}
					</span>
				</p>
			</Dialog>
			<Dialog
				header="Reject Correction Request"
				visible={showRejectDialog}
				footer={RejectionFooter}
				onHide={() => setShowRejectDialog(false)}
			>
				<p className="mt-5 px-5 bold-text">
					Confirm rejection of Service <b>{selectedItem?.serviceName}</b> for
					months
					<span className="bold-text">
						{capitalizeMonths(selectedItem?.months)}
					</span>{' '}
					from {selectedItem?.sourcecc} -{' '}
					<span className="bold-text">
						{getCostCenterDetails(selectedItem?.sourceccId)?.name}
					</span>{' '}
					to{' '}
					<span className="bold-text">
						{selectedItem?.targetcc} -{' '}
						{getCostCenterDetails(selectedItem?.targetccId)?.name} ?
					</span>
				</p>
				<div className="p-fluid grid formgrid mt-5">
					<div className={`field col-12 mt-3`}>
						<span className="p-float-label">
							<InputTextarea
								value={rejectionComments}
								onChange={(e: any) => setRejectionComments(e.target.value)}
								id="rejectionComment"
							/>
							<label htmlFor="rejectionComment">Rejection Comment</label>
						</span>
					</div>
				</div>
			</Dialog>
			{selectedItem && currentUser && (
				<MailITTResponsible
					defaultTo={[selectedItem?.responsible]}
					defaultCC={[currentUser?.email]}
					defaultSubject="Reaasignment of Cost Center For Service"
					defaultMessage={mailerDataMessage(selectedItem, currentUser)}
					selectedItem={selectedItem}
				/>
			)}
		</>
	);
};

export default CorrectionApproval;
