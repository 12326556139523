import { useEffect, useState } from 'react';
import CostCenterCard from '../../components/cost-center-card';
import ExtraActions from '../../components/extra-actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import '../../views/actuals/styles.scss';
import {
	useGetAllICAQuery,
	useGetAllPicasILVQuery,
	useGetAllNewPicasILVQuery,
	useLazyGetAllICAQuery,
} from '../../store/apiSlice';
import DisplayIcon from '../../components/icon';
import {
	getIsAdmin,
	setAssetListModal,
	setICAExportModals,
	setMailers,
} from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {
	useDeleteICAVersionMutation,
	useDeletePicasICAVersionMutation,
	useDeleteNewPicasICAVersionMutation,
	useIcaGenerationMutation,
	usePicasGenerationMutation,
	useNewPicasGenerationMutation,
	useUpdateICAMutation,
	useGetICAGeneratedMonthsQuery,
	useLazyGetServiceTypesQuery,
	useGenerateCloudCostsMutation,
} from '../../store/icaApi';
import { setServers } from 'dns';
import {
	downloadResponseAsFile,
	formatDateDDMMYYYY,
} from '../../utils/utility-function';
import useDownloadFile from '../../hooks/useDownloadFile';
import useFileCreator from '../../hooks/useFileCreator';
import MailICAReport from '../../components/email/email-ica-report';
import SlstAlert from './slst-alrt';
import SAConfiguration from './sa-configuration';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { TabView, TabPanel } from 'primereact/tabview';
import { useGetUploadTypesQuery } from '../../store/chargingReportApi';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';

const ICAExport: React.FC = function () {
	const [generateICA, { isLoading: isGeneratingICA }] =
		useIcaGenerationMutation();
	const [generatePICAS, { isLoading: isGeneratingPicas }] =
		usePicasGenerationMutation();
	const [generateNewPICAS, { isLoading: isGeneratingNewPicas }] =
		useNewPicasGenerationMutation();
	const [deleteICAVersion, { isLoading: isICADeleting }] =
		useDeleteICAVersionMutation();
	const [deletePicasICAVersion, { isLoading: isPicasICADeleting }] =
		useDeletePicasICAVersionMutation();
	const [deleteNewPicasICAVersion, { isLoading: isNewPicasICADeleting }] =
		useDeleteNewPicasICAVersionMutation();
	const {
		data: dataICA,
		isFetching,
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetAllICAQuery();
	const [fetchAllICA, { isFetching: isLoadingICA }] = useLazyGetAllICAQuery();
	const {
		data: dataPicas,
		isFetching: isFetchingPicas,
		isLoading: isLoadingPicas,
		isSuccess: isSuccessPicas,
		isError: isErrorPicas,
		refetch,
		error: errorPicas,
	} = useGetAllPicasILVQuery();
	const {
		data: dataNewPicas,
		isFetching: isFetchingNewPicas,
	} = useGetAllNewPicasILVQuery();
	const [versionModal, setVersionModal] = useState(false);
	const [version, setVersion] = useState('');
	const [modalType, setModalType] = useState<'ICA' | 'PICAS' | 'NEWPICAS' | ''>('');
	const [deleteModal, setDeleteModal] = useState('');
	const [id, setId] = useState(0);
	const { downloadFile } = useDownloadFile();
	const { createFile } = useFileCreator();
	const [editICA, setEditICA] = useState<any>(undefined);
	const [activeIndex, setActiveIndex] = useState(1);
	// const [updateICA] = useUpdateICAMutation();
	const [updateICA, { isLoading: isICAUpdate }] =
		useUpdateICAMutation();
	useEffect(() => {
		document.title = 'Cost Allocation | XCharge';
	}, []);

	const [selectedUploadType, setSelectedUploadType] = useState<any>(undefined);
	const [selectedService, setSelectedService] = useState<any>(undefined);
	const { data: uploadType = [], isLoading: isTypeFetching, } = useGetUploadTypesQuery();
	const { data: ICAMonthsData = [], isLoading: isMonthsFetching, } = useGetICAGeneratedMonthsQuery();
	const [fetchServiceTypes, { data: serviceTypes = [], isLoading: isFetchingService, }] = useLazyGetServiceTypesQuery();

	const [selectedMonths, setSelectedMonths] = useState<any>([]);

	useEffect(() => {
		selectedUploadType?.name?.toLowerCase() === 'cloudcosts' && fetchServiceTypes({ uploadType: selectedUploadType.name })
	}, [selectedUploadType])

	const [generateCloudCosts, { isLoading: isCloudCostsGenerating }] = useGenerateCloudCostsMutation();

	const isAdmin = useSelector(getIsAdmin);

	// useEffect(() => {
	// 	console.log(ICAMonthsData, 'icamonths');
	// }, [ICAMonthsData])

	// const monthlyData = {
	// 	"uploadedMonth": [
	// 		{
	// 			month: 'January',
	// 			isICAGenerated: false
	// 		},
	// 		{
	// 			month: 'February',
	// 			isICAGenerated: true
	// 		},
	// 		{
	// 			month: 'March',
	// 			isICAGenerated: true
	// 		},
	// 		{
	// 			month: 'April',
	// 			isICAGenerated: false
	// 		},
	// 	]
	// }


	const onMonthChange = (e: { value: any; checked: boolean }) => {
		let _selectedMonths = [...selectedMonths];

		console.log(':: e ::', e);
		console.log(':: selected category ::', _selectedMonths);
		if (e.checked) {
			_selectedMonths.push(e.value);
		} else {
			for (let i = 0; i < _selectedMonths.length; i++) {
				const selectedCategory = _selectedMonths[i];

				if (selectedCategory.uploadedMonth === e.value.uploadedMonth) {
					_selectedMonths.splice(i, 1);
					break;
				}
			}
		}

		setSelectedMonths(_selectedMonths);
	};

	const deleteFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						setModalType('');
						setDeleteModal('');
						setId(0);
					}}
					className="p-button-text"
				/>
				<Button
					label="Delete"
					icon="pi pi-trash"
					onClick={async () => {
						if (modalType === 'ICA') {
							await deleteICAVersion(id)
								.unwrap()
								.then((payoad: any) =>
									toast.success('Deleted ICA Report Successfully')
								)
								.catch((e: any) => {
									toast.error(e.data.message);
								});
						} else if ((modalType === 'PICAS')) {
							await deletePicasICAVersion(id)
								.unwrap()
								.then((payoad: any) =>
									toast.success('Deleted Picas Report Successfully')
								)
								.catch((e: any) => {
									toast.error(e.data.message);
								});
						} else if ((modalType === 'NEWPICAS')) {
							await deleteNewPicasICAVersion(id)
								.unwrap()
								.then((payoad: any) =>
									toast.success('Deleted Workplace Report Successfully')
								)
								.catch((e: any) => {
									toast.error(e.data.message);
								});
						}
						setModalType('');
						setDeleteModal('');
						setId(0);
					}}
				/>
			</div>
		);
	};
	const clearFormValue = () => {
		setSelectedUploadType(undefined);
		setSelectedMonths(undefined);
		setSelectedService(undefined);
		setModalType('');
		setVersionModal(false);
		setVersion('');
	}
	const versionFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						clearFormValue();
					}}
					className="p-button-text"
				/>
				<Button
					label="Save"
					icon="pi pi-save"
					loading={modalType === 'ICA' ? isGeneratingICA : modalType === 'PICAS' ? isGeneratingPicas : isGeneratingNewPicas}
					onClick={async () => {
						if (modalType === 'ICA') {
							// if (
							// 	dataICA?.findIndex((item) => item.status === 'Planned') !== -1
							// ) {
							// 	toast.error(
							// 		'Cost Allocation with status - Planned already exists. Kindly delete it and then proceed with generating new Cost Allocation'
							// 	);
							// 	setVersionModal(false);
							// 	setVersion('');
							// 	setModalType('');
							// } else {
								if (selectedUploadType?.name?.toLowerCase() === 'cloudcosts') {
									const payload = {
										icaVersion: version,
										body: {
											months: selectedMonths.map((obj: any) => obj.uploadedMonth),
											servicetype: selectedService.map((obj: any) => obj.name)
										}
									}
									await generateCloudCosts(payload)
										.unwrap()
										.then(async (payoad: any) => {
											console.log('Payload :: ', payoad);
											toast.success('Generated Cloud Costs Report Successfully');
											clearFormValue();
										})
										.catch((e: any) => {
											// let errorData = JSON.parse(`${e.data}`);
											let errorData = e.data;
											console.log(errorData);
											if (e.data) {
												toast.error(errorData.message);
											} else {
												toast.error('Error while generating Cloud Costs report')
											}
										});
								} else {
									await generateICA(version)
										.unwrap()
										.then(async (payoad: any) => {
											console.log('Payload :: ', payoad);
											// createFile(
											// 	`ICA-Report_${new Date().toISOString()}.xlsx`,
											// 	payoad
											// );
											toast.success('Generated ICA Report Successfully');
											clearFormValue();
										})
										.catch((e: any) => {
											// let errorData = JSON.parse(`${e.data}`);
											let errorData = e.data;
											console.log(errorData);
											if (e.data) {
												toast.error(errorData.message);
											} else {
												toast.error('Error while generating ICA report')
											}
										});
								}
								setVersionModal(false);
								setVersion('');
								setModalType('');
							// }
							// } else if ((modalType === 'PICAS')) {
							// 	await generatePICAS(version)
							// 		.unwrap()
							// 		.then((payoad: any) =>
							// 			toast.success('Generated Picas Cost Allocation Successfully')
							// 		)
							// 		.catch((e: any) => {
							// 			toast.error(e.data.message);
							// 		});

							// 	setVersionModal(false);
							// 	setVersion('');
							// 	setModalType('');
						} else if ((modalType === 'NEWPICAS')) {
							await generateNewPICAS(version)
								.unwrap()
								.then((payoad: any) => {
									toast.success('Generated Workplace Cost Allocation Successfully');
									clearFormValue();
								})
								.catch((e: any) => {
									// let errorData = JSON.parse(`${e.data}`);
									let errorData = e.data;
									console.log(errorData);
									if (e.data) {
										toast.error(errorData.message);
									} else {
										toast.error('Error while generating Workplace Cost Allocation')
									}
								});

							setVersionModal(false);
							setVersion('');
							setModalType('');

						}
					}}
					disabled={version.length === 0}
				/>
			</div>
		);
	};
	const dispatch = useDispatch();

	function onRowclick(data: any) {
		// console.log(data);
	}
	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Cost Allocation</h5>
					<Button
						label="Generate Cost Allocation"
						icon="pi pi-plus"
						className="p-button-sm ml-5"
						onClick={() => {
							setModalType('ICA');
							setVersionModal(true);
						}}
					/>
				</div>
				<div className="actions flex">
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Set SLst Alert"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							dispatch(setICAExportModals({ slstArt: true }));
						}}
					>
						<DisplayIcon icon="tablerArrowSwap" className="action-icon" />
					</Button>
					<Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Set SA Configuration"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							dispatch(setICAExportModals({ saConfiguration: true }));
						}}
					>
						<DisplayIcon icon="settings" className="action-icon" />
					</Button>
				</div>
			</div>
		);
	};
	const headerPicas = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Picas Cost Allocation</h5>
					<Button
						label="Generate Picas Allocation"
						icon="pi pi-plus"
						className="p-button-sm ml-5"
						onClick={() => {
							setModalType('PICAS');
							setVersionModal(true);
						}}
					/>
					{/* CC-910: PICAS - generate ILV Excel with Workplace Report data */}
					{/* <Button
						label="Generate Workplace Allocation"
						icon="pi pi-plus"
						className="p-button-sm ml-5"
						onClick={() => {
							setModalType('NEWPICAS');
							setVersionModal(true);
						}}
					/> */}
				</div>
			</div>
		);
	};
	const headerNewPicas = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Workplace Cost Allocation</h5>
					{/* <Button
						label="Generate Picas Allocation"
						icon="pi pi-plus"
						className="p-button-sm ml-5"
						onClick={() => {
							setModalType('PICAS');
							setVersionModal(true);
						}}
					/> */}
					{/* CC-910: PICAS - generate ILV Excel with Workplace Report data */}
					<Button
						label="Generate Workplace Allocation"
						icon="pi pi-plus"
						className="p-button-sm ml-5"
						onClick={() => {
							setModalType('NEWPICAS');
							setVersionModal(true);
						}}
					/>
				</div>
			</div>
		);
	};
	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				{
					editICA !== undefined && rowData.id === editICA.id ? (
						null
					) : (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'right' }}
								onClick={() => {
									downloadFile(
										`chargingReport/icaexport?versionId=${rowData.id}`,
										`ICA-Report_${new Date().toISOString()}.xlsx`
									);
								}}
								tooltip="Export ICA Report"
							>
								<DisplayIcon icon="tablerFileExport" className="table-action" />
							</Button>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'right' }}
								onClick={() => {
									setModalType('ICA');
									setId(rowData.id);
									dispatch(setMailers({ icaReport: true }));
								}}
								tooltip="Mail ICA Report"
							>
								<DisplayIcon icon="tablerMail" className="table-action" />
							</Button>
						</>
					)
				}
				{
					editICA === undefined ? (
						<>
							{rowData.status === 'Booked' ? null : (
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									onClick={() => {
										setModalType('ICA');
										setDeleteModal(rowData.name);
										setId(rowData.id);
									}}
									tooltip="Delete ICA Report"
								>
									<DisplayIcon icon="tablerTrash" className="table-action" />
								</Button>
							)}
							{rowData.status === 'Booked' ? null : (
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									onClick={() => {
										// setModalType('ICA');
										console.log(rowData);
										setEditICA(rowData);
									}}
									tooltip="Edit ICA Report"
								>
									<DisplayIcon icon="edit" className="table-action" />
								</Button>
							)}
						</>
					) : (

						rowData.status === 'Booked' ? null : (
							<>
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									loading={isICAUpdate}
									onClick={async () => {
										// setModalType('ICA');
										console.log(editICA);
										if (editICA.status !== "Planned") {
											await updateICA({ versionId: editICA.id, status: editICA.status })
												.unwrap()
												.then((payload: any) => {
													toast.success('Status Updated Successfully')
													setEditICA(undefined);
												})
												.catch((error: any) => toast.error(error.data.message));
										}
										// setEditICA(rowData);
									}}
									tooltip="Save Status"
								>
									<DisplayIcon icon="tablerSave" className="table-action" />
								</Button>
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									onClick={() => {
										setEditICA(undefined);
									}}
									tooltip="Cancel Update"
								>
									<DisplayIcon icon="tablerCancel" className="table-action" />
								</Button>

							</>
						)
					)
				}
			</div>
		);
	};
	const actions2 = (rowData: any) => {
		return (
			<div className="table-menu">
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						activeIndex === 0 ?
							downloadFile(
								`picasReport/generatepicasilv?versionId=${rowData.id}&version=${rowData.name}`,
								`PICAS-ILV_${new Date().toISOString()}.xlsx`
							) :
							downloadFile(
								`picasReport/generatenewpicasilv?versionId=${rowData.id}&version=${rowData.name}`,
								`WORKPLACE-ILV_${new Date().toISOString()}.xlsx`
							)
					}}
					tooltip={`Export ${activeIndex === 0 ? "Picas" : "Workplace"} Allocation Report`}
				>
					<DisplayIcon icon="tablerFileExport" className="table-action" />
				</Button>
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						activeIndex === 0 ? setModalType('PICAS') : setModalType('NEWPICAS');
						setId(rowData.id);
						dispatch(setMailers({ icaReport: true }));
					}}
					tooltip={`Mail ${activeIndex === 0 ? "Picas" : "Workplace"} Allocation Report`}
				>
					<DisplayIcon icon="tablerMail" className="table-action" />
				</Button>
				<Button
					className="p-button-rounded p-button-text p-button-icon-only"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						activeIndex === 0 ? setModalType('PICAS') : setModalType('NEWPICAS');
						setDeleteModal(rowData.name);
						setId(rowData.id);
					}}
					tooltip={`Delete ${activeIndex === 0 ? "Picas" : "Workplace"} Allocation Report`}
				>
					<DisplayIcon icon="tablerTrash" className="table-action" />
				</Button>
			</div>
		);
	};

	const statusList = [
		{ status: "Booked", value: "Booked" },
		{ status: "Planned", value: "Planned" },
	]
	const statusBody = (rowData: any) => {
		if (editICA !== undefined && rowData.id === editICA.id) {
			return (
				<Dropdown
					value={editICA.status}
					options={statusList}
					onChange={(item) => {
						console.log(item)
						setEditICA({ ...editICA, status: item.value });
						console.log(editICA)
					}}
					optionLabel="status"
					id="icaStatus"
					name="icaStatus"
				/>
			)
		} else {
			return rowData.status;
		}
	}
	return (
		<div className={`grid`}>
			<div className="cards">
				<DataTable
					header={header}
					value={dataICA}
					loading={isFetching}
					responsiveLayout="scroll"
					onRowClick={(event) => onRowclick(event)}
					className="cost-allocation"
				>
					<Column field="name" header="Month Of Upload" sortable></Column>
					<Column
						header="Upload Date"
						field="uploadedOn"
						body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
						sortable
					></Column>
					<Column
						field="uploadedBy.firstName"
						header="Uploader"
						body={(data) =>
							`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
						}
						sortable
					></Column>
					<Column field="status" header="Status" body={statusBody} sortable></Column>
					<Column
						body={actions}
						align="left"
						bodyStyle={{ width: '10%' }}
					></Column>
				</DataTable>
			</div>
			{
				isAdmin &&
				<div className="cards mt-4">
					{/* <TabView className='picas-tab' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel header="Picas Report">
						<DataTable
							loading={isFetchingPicas}
							value={dataPicas}
							responsiveLayout="scroll"
							onRowClick={(event) => onRowclick(event)}
							header={headerPicas}
							className="picas-allocation"
						>
							<Column field="name" header="Month Of Upload" sortable></Column>
							<Column
								header="Upload Date"
								field="uploadedOn"
								body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
								sortable
							></Column>
							<Column
								field="uploadedBy.firstName"
								header="Uploader"
								body={(data) =>
									`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
								}
								sortable
							></Column>
							<Column field="status" header="Status" sortable></Column>
							<Column
								body={actions2}
								align="center"
								bodyStyle={{ width: '10%' }}
							></Column>
						</DataTable>
					</TabPanel>
					<TabPanel header="Workplace Report"> */}
					<DataTable
						loading={isFetchingNewPicas}
						value={dataNewPicas}
						responsiveLayout="scroll"
						onRowClick={(event) => onRowclick(event)}
						header={headerNewPicas}
						className="picas-allocation"
					>
						<Column field="name" header="Month Of Upload" sortable></Column>
						<Column
							header="Upload Date"
							field="uploadedOn"
							body={(data) => formatDateDDMMYYYY(data.uploadedOn)}
							sortable
						></Column>
						<Column
							field="uploadedBy.firstName"
							header="Uploader"
							body={(data) =>
								`${data.uploadedBy.firstName} ${data.uploadedBy.lastName}`
							}
							sortable
						></Column>
						<Column field="status" header="Status" sortable></Column>
						<Column
							body={actions2}
							align="center"
							bodyStyle={{ width: '10%' }}
						></Column>
					</DataTable>
					{/* </TabPanel>
				</TabView> */}
				</div>
			}
			<Dialog
				header={
					modalType === 'ICA'
						? 'Generate Cost Allocation' : modalType === 'PICAS'
							? 'Generate Picas Allocation' : 'Generate Workplace Allocation'
				}
				visible={versionModal}
				style={modalType === 'ICA' ? { width: '60vw' } : { width: '25vw' }}
				footer={versionFooter}
				onHide={() => {
					clearFormValue();
				}}
			>
				<h5>{modalType === 'ICA' ? 'Set ICA Version' : modalType === 'PICAS' ? 'Set Picas Version' : 'Set Workplace Version'}</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						{modalType === 'ICA' &&
							<>
								<div className='field col-4'>
									<span className="p-float-label">
										<Dropdown
											value={selectedUploadType}
											options={uploadType}
											onChange={(item) => {

												setSelectedUploadType(item.value);
												console.log(selectedUploadType);

											}}
											optionLabel="name"
											id="uploadType"
											name="Upload Type"
										/>
										<label htmlFor="uploadType">Upload Type</label>
									</span>
								</div>
								{selectedUploadType?.name?.toLowerCase() === 'cloudcosts' &&
									<div className="field col-4">
										<span className="p-float-label">
											<MultiSelect value={selectedService} onChange={(e) => setSelectedService(e.value)} options={serviceTypes} optionLabel="name"
												filter placeholder="Select Service" maxSelectedLabels={3} id="serviceType" style={{ "height": "35px" }} />
											<label htmlFor="serviceType">Select Service</label>
										</span>
									</div>
								}
							</>
						}

						<div className={modalType === 'ICA' ? `field col-4` : 'field col-12'}>
							<span className="p-float-label">
								<InputText
									value={version}
									onChange={(e) => setVersion(e.target.value)}
									id="version"
								/>
								<label htmlFor="version">
									{modalType === 'ICA' ? 'ICA Version' : modalType === 'PICAS' ? 'Picas Version' : ' Workplace Version'}
								</label>
							</span>
						</div>
					</div>

					{
					modalType === 'ICA' && selectedUploadType?.name?.toLowerCase() === 'cloudcosts' &&
						<>
							<h5 className='mt-3'>Select Months</h5>
							<div className="grid col-12 mt-3">
								<div className="flex field col-12">
									{ICAMonthsData &&
										ICAMonthsData.map((item: any, index: any) => {
											return (
												<div key={index} className="field-checkbox mr-4">
													<Checkbox
														inputId={item.uploadedMonth}
														name="months"
														value={item}
														onChange={onMonthChange}
														checked={selectedMonths.some(
															(obj: any) => obj.uploadedMonth === item.uploadedMonth
														)}
														disabled={item.isICAGenerated}
														tooltip={
															item.isICAGenerated
																? 'ICA reported generated for this month already!'
																: ''
														}
														tooltipOptions={{ position: 'top' }}
													/>
													<label htmlFor={item.uploadedMonth}>{item.uploadedMonth}</label>
												</div>
											);
										})}
									<div className="field-checkbox mr-4">
										{ICAMonthsData.filter(
											(item: any) => !item.isICAGenerated
										).length === 0 ? (
											<Message severity="warn" text="All Services Are Booked" />
										) : selectedMonths.length ===
											ICAMonthsData.filter(
												(item: any) => !item.isICAGenerated
											).length ? (
											<>
												{' '}
												<Checkbox
													inputId="no-cc"
													name="no-months"
													value={0}
													onChange={(e) => {
														setSelectedMonths([]);
													}}
													checked={
														selectedMonths.length ===
														ICAMonthsData.filter(
															(item: any) => !item.isICAGenerated
														).length
													}
												/>
												<label htmlFor="no-cc">Unselect All</label>
											</>
										) : (
											<>
												{' '}
												<Checkbox
													inputId="all-cc"
													name="all-months"
													value={1}
													onChange={(e) => {
														setSelectedMonths([
															...ICAMonthsData.filter(
																(item: any) => !item.isICAGenerated
															),
														]);
													}}
													checked={
														selectedMonths.length ===
														ICAMonthsData.filter(
															(item: any) => !item.isICAGenerated
														).length
													}
												/>
												<label htmlFor="all-cc">Select All</label>{' '}
											</>
										)}
									</div>
								</div>
							</div>
						</>
					}

				</div>
			</Dialog>
			<Dialog
				header={
					modalType === 'ICA'
						? 'Delete Cost Allocation'
						: 'Delete Picas Allocation'
				}
				visible={deleteModal.length > 0}
				footer={deleteFooter}
				onHide={() => {
					setDeleteModal('');
				}}
			>
				<h5>
					{modalType === 'ICA'
						? 'Confirm ICA Deleteion'
						: 'Confirm Picas Deletion'}
				</h5>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						<p>
							Do you really want to delete the Cost Allocation version :{' '}
							<span className="bold-text">{deleteModal}</span> ? <br />
							<br />
							Your action is irreversible !
						</p>
					</div>
				</div>
			</Dialog>
			<MailICAReport versionId={id} target={modalType} />
			<SlstAlert />
			<SAConfiguration />
		</div>
	);
};

export default ICAExport;
