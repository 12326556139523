import { number } from 'yup';
import { IProp } from '../components/cost-center-card';
import { IICAConfigResult, IICASaS, ISlstArt } from '../types/ica.interface';
import {
	ICommentRequest,
	ICommentResponse,
	IServiceDetails,
} from '../types/service.interfaces';
import { IServiceRequest } from '../views/service';
import { baseApi } from './baseApi';

const icaApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllArchiveData: builder.query<IProp[], number>({
			query: (versionId) =>
				`archive/costcenters?isAll=true&versionId=${versionId}`,
			providesTags: (result, error, arg) => [
				{
					type: 'AllArchive' as const,
					versionId: arg,
				},
			],
		}),
		getOwnArchiveData: builder.query<IProp[], number>({
			query: (versionId) =>
				`archive/costcenters?isAll=false&versionId=${versionId}`,
			providesTags: (result, error, arg) => [
				{
					type: 'OwnArchive' as const,
					versionId: arg,
				},
			],
		}),
		getAllArchivedServices: builder.query<
			IProp,
			{ costCenterId: number; versionId: number }
		>({
			query: ({ costCenterId, versionId }) =>
				`archive/getallservices?costcenterId=${costCenterId}&versionId=${versionId}`,
			transformResponse: (response: IProp, meta, arg) => ({
				...response,
				services: response.services.map((service, index) => ({
					...service,
					id_pk: index,
				})),
			}),
		}),
		getAllArchivedComments: builder.mutation<ICommentResponse, ICommentRequest>(
			{
				query: (CommentRequest) => ({
					url: `archive/getallcomments`,
					method: 'POST',
					body: CommentRequest,
				}),
			}
		),
		getAllMonthlyServiceDataArchive: builder.mutation<
			IServiceDetails,
			IServiceRequest
		>({
			query: (serviceRequest) => ({
				url: `/archive/getservicedata`,
				method: 'POST',
				body: serviceRequest,
			}),
		}),
		
		deleteArchive: builder.mutation<any, void>({
			query: () => ({
				url: `archive/deletetarchive`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useGetAllArchiveDataQuery,
	useGetOwnArchiveDataQuery,
	useLazyGetAllArchiveDataQuery,
	useLazyGetAllArchivedServicesQuery,
	useGetAllArchivedServicesQuery,
	useLazyGetOwnArchiveDataQuery,
	useGetAllArchivedCommentsMutation,
	useGetAllMonthlyServiceDataArchiveMutation,
	useDeleteArchiveMutation,
} = icaApi;
