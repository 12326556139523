// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IProp } from '../components/cost-center-card/index';
import { IChargingReport } from '../views/charging-report-upload';
import { ICostCenterData } from '../views/cost-center';
import { IMappingData } from '../views/mapping';
import { IServiceData, IServiceRequest } from '../views/service/index';
import { IAssetList } from '../views/asset-list-upload/set-asset-price';
import { IAllUsers, IUsers } from '../views/users';
import { baseApi } from './baseApi';
import { IServiceDetails } from '../types/service.interfaces';
const actualsEndPoint = 'actuals';

export const apiSlice = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllActiveCostCenters: builder.query<IProp[], void>({
			query: () => `/${actualsEndPoint}/costcenters?isAll=true`,
		}),
		getOwnActiveCostCenters: builder.query<IProp[], void>({
			query: () => `/${actualsEndPoint}/costcenters?isAll=false`,
		}),
		getAllActivePicasCostCenters: builder.query<IProp[], void>({
			query: () => `/picasReport/costcentersworkplace?isAll=true`,
		}),
		getOwnActivePicasCostCenters: builder.query<IProp[], void>({
			query: () => `/picasReport/costcentersworkplace?isAll=false`,
		}),
		getAllServices: builder.query<IProp, number>({
			query: (costCenterId) =>
				`/${actualsEndPoint}/getallservices?costcenterId=${costCenterId}`,
			transformResponse: (response: IProp, meta, arg) => ({
				...response,
				services: response.services.map((service, index) => ({
					...service,
					id_pk: index,
				})),
			}),
			providesTags: (result, error, arg) =>
				result
					? [
							{
								type: 'Services' as const,
								costCenterId: result.costCenterId,
							},
							'Services',
					  ]
					: ['Services'],
		}),

		getAllChargingReport: builder.query<IChargingReport[], void>({
			query: () => `/chargingReport/all`,
			providesTags: ['ChargingReport'],
		}),
		getAzureChargingReport: builder.query<IChargingReport[], void>({
			query: () => `/azure/all`,
			providesTags: ['AzureReport'],
		}),
		getAssetListUpload: builder.query<IChargingReport[], void>({
			query: () => `picasReport/all`,
			providesTags: ['PicasReports'],
		}),
		getAssetNewListUpload: builder.query<IChargingReport[], void>({
			query: () => `picasReport/allnew`,
			providesTags: ['PicasNewReports'],
		}),
		getAllICA: builder.query<IChargingReport[], void>({
			query: () => `chargingReport/getallica`,
			providesTags: ['CostAllocation'],
		}),
		getAllPicasILV: builder.query<IChargingReport[], void>({
			query: () => `picasReport/getpicasilv`,
			providesTags: ['PicasAllocation'],
		}),
		getAllNewPicasILV: builder.query<IChargingReport[], void>({
			query: () => `picasReport/getnewpicasilv`,
			providesTags: ['NewPicasAllocation'],
		}),

		getAllCostCenters: builder.query<ICostCenterData[], void>({
			query: () => `costcenter/all?Id=0&regionId=0`,
		}),

		getICAVersion: builder.query<IChargingReport[], void>({
			query: () => `archive/geticaversions`,
		}),

		getClusterKeywords: builder.query<any, void>({
			query: () => `actuals/getclusterkeywords`,
			providesTags: ['ClusterKeywords'],
		}),
		getallnewasset: builder.query<IProp[], void>({
			query: () => `picasReport/getallnewasset`,
		}),
	}),
});

export const {
	useGetClusterKeywordsQuery,
	useGetOwnActiveCostCentersQuery,
	useLazyGetOwnActiveCostCentersQuery,
	useGetAllActiveCostCentersQuery,
	useLazyGetAllActiveCostCentersQuery,
	useGetOwnActivePicasCostCentersQuery,
	useLazyGetOwnActivePicasCostCentersQuery,
	useGetAllActivePicasCostCentersQuery,
	useLazyGetAllActivePicasCostCentersQuery,
	useGetAllServicesQuery,
	useGetAllChargingReportQuery,
	useGetAzureChargingReportQuery,
	useGetAssetListUploadQuery,
	useLazyGetAssetListUploadQuery,
	useGetAssetNewListUploadQuery,
	useLazyGetAssetNewListUploadQuery,
	useGetAllICAQuery,
	useGetAllPicasILVQuery,
	useGetAllNewPicasILVQuery,
	useGetAllCostCentersQuery,
	useGetICAVersionQuery,
	useLazyGetAllICAQuery,
	useLazyGetAllServicesQuery,
	useGetallnewassetQuery,
} = apiSlice;
