import {
	createSlice,
	createEntityAdapter,
	createSelector,
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { apiSlice } from '../apiSlice';

import store from '../index';

export const selectAllCostCenters =
	apiSlice.endpoints.getAllActiveCostCenters.select();

export const selectOwnCostCenters =
	apiSlice.endpoints.getOwnActiveCostCenters.select();

const ownCostCenters: any[] = [];

export const selectedCostCenters = createSelector(
	selectOwnCostCenters,
	(costCenters) => costCenters.data ?? []
);

export const xyx = createSelector(selectedCostCenters, (costCenters) => {
	console.log(':: Calculating Filter ::');
	const homeFilters = store.getState().common.homeFilters;
	let output;
	output =
		store.getState().common.searchTerm !== ''
			? costCenters.filter(
					(items) =>
						Object.values(items).findIndex((chunk: any) =>
							chunk.toString().includes(store.getState().common.searchTerm)
						) !== -1
			  )
			: costCenters;

	output =
		homeFilters.costCenterFilter.target_costcenter !== ''
			? output.filter(
					(d) => d.costCenter === homeFilters.costCenterFilter.target_costcenter
			  )
			: output;
	output =
		homeFilters.targetResponsilbeFilter.user_responsible !== ''
			? output.filter(
					(d) =>
						d.userResponsible ===
						homeFilters.targetResponsilbeFilter.user_responsible
			  )
			: output;

	return output;
});
