import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useMemo, useState } from 'react';
import { useChargingReportOverviewMutation } from '../../store/chargingReportApi';
import { formatCurrency } from '../../utils/utility-function';
import { MultiSelect } from 'primereact/multiselect';
import DisplayIcon from '../../components/icon';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAdmin, getIsCostController, setDataProcessingModal } from '../../store/commonSlice';
import useDownloadFile from '../../hooks/useDownloadFile';
import UploadChargingReport from '../charging-report-upload/upload-charging-report';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import CustomAutoComplete from '../../components/autocomplete/autocomplete';
import './index.scss';
import ReactTooltip from 'react-tooltip';
import { Button } from 'primereact/button';
const ChargingReportPivot = () => {
	const [getChargingReportOverview, { isLoading: isFetchingChargingReport }] =
		useChargingReportOverviewMutation();
	const [selectedSum, setSelectedSum] = useState(0);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [responseData, setResponseData] = useState<any>([]);
	useEffect(() => {
		document.title = 'Charging Report Pivot | XCharge';
	}, []);
	useEffect(() => {
		const chargingReportData = async () => {
			const output = await getChargingReportOverview(selectedYear).unwrap();
			setResponseData(output);
		};
		chargingReportData();
	}, [selectedYear]);
	const isAdmin = useSelector(getIsAdmin);
	const dispatch = useDispatch();
	const { downloadFile } = useDownloadFile();
	const unique = (value: any, index: any, self: any) => {
		return self.indexOf(value) === index;
	};
	const columns = [
		{ field: 'serviceId', header: 'Service ID' },
		{ field: 'serviceName', header: 'Service Name' },
		{ field: 'additionalInfo', header: 'Add. Info' },
		{ field: 'targetResponsible', header: 'Responsible' },
		{ field: 'targetCostCenter', header: 'Cost Center' },
		{ field: 'serviceMonth', header: 'Month' },
		{ field: 'ioNumber', header: 'IO Number' },
		{ field: 'status', header: 'Status' },
		{ field: 'total', header: 'Total' },
	];

	let colOptions = [];
	for (let col of columns) {
		colOptions.push({ label: col.header, value: col });
	}

	const [nodes, setNodes] = useState<any>([]);
	const [cols, setCols] = useState(columns);
	const computedData = useMemo(() => {
		const finalOutput: any[] = [];

		if (responseData && responseData.length > 0) {
			const uniqueSAID = responseData
				.map((item: any) => item.said)
				.filter(unique);
			uniqueSAID.forEach((said: any, iSaid: any) => {
				const filterdWithSAID = responseData.filter(
					(dataItem: any) => dataItem.said == said
				);
				const uniqueServiceId = filterdWithSAID
					.map((dataItem: any) => dataItem.serviceId)
					.filter(unique);
				const out0: any = {};
				out0.key = iSaid.toString();
				out0.data = {};
				out0.data.said = said;
				out0.data.serviceId = '';
				out0.data.serviceName = '';
				out0.data.additionalInfo = '';
				out0.data.targetResponsible = '';
				out0.data.targetCostCenter = '';
				out0.data.serviceMonth = '';
				out0.data.ioNumber = '';
				out0.data.status = '';
				out0.data.total = formatCurrency(
					filterdWithSAID.reduce(
						(acc: any, item: any) => (acc += Number(item.amount)),
						0
					)
				);
				out0.children = [];
				uniqueServiceId.forEach((serviceId: any, iServiceId: any) => {
					const filterdWithServiceId = filterdWithSAID.filter(
						(dataItem: any) => dataItem.serviceId === serviceId
					);
					const uniqueServiceName = filterdWithServiceId
						.map((dataItem: any) => dataItem.serviceName)
						.filter(unique);
					const r1 = filterdWithSAID.find(
						(dataItem: any) => dataItem.serviceId === serviceId
					);
					const out1: any = {};
					out1.key = iSaid + '-' + iServiceId;
					out1.data = {};
					out1.data.said = said;
					out1.data.serviceId = serviceId;
					out1.data.serviceName = '';
					out1.data.additionalInfo = '';
					out1.data.targetResponsible = '';
					out1.data.targetCostCenter = '';
					out1.data.serviceMonth = '';
					out1.data.ioNumber = '';
					out1.data.status = '';
					out1.data.total = formatCurrency(
						filterdWithServiceId.reduce(
							(acc: any, item: any) => (acc += Number(item.amount)),
							0
						)
					);
					out1.children = [];
					out0.children.push(out1);
					serviceNameMapper(
						out1,
						uniqueServiceName,
						filterdWithServiceId,
						said,
						serviceId,
						iSaid,
						iServiceId
					);
				});

				finalOutput.push(out0);
			});
		}
		return finalOutput;
	}, [responseData]);

	const _columns = cols.map((col, i) => {
		ReactTooltip.rebuild();
		return <Column key={col.field} field={col.field} header={col.header} />;
	});

	function serviceNameMapper(
		out1: any,
		uniqueServiceName: any,
		filterdWithServiceId: any,
		said: any,
		serviceId: any,
		iSaid: any,
		iServiceId: any
	) {
		if (uniqueServiceName.length === 1) {
			let serviceName = uniqueServiceName[0];
			let iServiceName = 0;
			out1.data.serviceName = serviceName;
			const filterdWithServiceName = filterdWithServiceId.filter(
				(dataItem: any) => dataItem.serviceName === serviceName
			);
			const uniqueAdditionalInfo = filterdWithServiceName
				.map((dataItem: any) => dataItem.aditionalInfo)
				.filter(unique);

			if (uniqueAdditionalInfo.length === 1) {
				const serviceResponsibleFinder = filterdWithServiceName.filter(
					(dataItem: any) => dataItem.aditionalInfo === uniqueAdditionalInfo[0]
				);
				const uniqueServiceResponsible = serviceResponsibleFinder
					.map((dataItem: any) => dataItem.targetResponsible)
					.filter(unique);
				const uniqueTargetCostCenter = serviceResponsibleFinder
					.map((dataItem: any) => dataItem.targetCostCenter)
					.filter(unique);
				const uniqueIoNumber = serviceResponsibleFinder
					.map((dataItem: any) => dataItem.ioNumber)
					.filter(unique);
				if (uniqueServiceResponsible.length === 1) {
					out1.data.targetResponsible = toUpperCase(
						uniqueServiceResponsible[0]
					);
				}
				if (uniqueTargetCostCenter.length === 1) {
					out1.data.targetCostCenter = uniqueTargetCostCenter[0];
				}
				if (uniqueIoNumber.length === 1) {
					out1.data.ioNumber = uniqueIoNumber[0];
				}
			}

			additionalInfoMapper(
				out1,
				uniqueAdditionalInfo,
				filterdWithServiceName,
				said,
				serviceId,
				serviceName,
				iSaid,
				iServiceId,
				iServiceName
			);
		} else {
			uniqueServiceName.forEach((serviceName: any, iServiceName: any) => {
				const filterdWithServiceName = filterdWithServiceId.filter(
					(dataItem: any) => dataItem.serviceName === serviceName
				);
				const uniqueAdditionalInfo = filterdWithServiceName
					.map((dataItem: any) => dataItem.aditionalInfo)
					.filter(unique);
				const out2: any = {};
				out2.key = iSaid + '-' + iServiceId + '-' + iServiceName;
				out2.data = {};
				out2.data.said = said;
				out2.data.serviceId = serviceId;
				out2.data.serviceName = serviceName;
				out2.data.additionalInfo = '';
				out2.data.targetResponsible = '';
				out2.data.targetCostCenter = '';
				out2.data.serviceMonth = '';
				out2.data.ioNumber = '';
				out2.data.status = '';
				out2.data.total = formatCurrency(
					filterdWithServiceName.reduce(
						(acc: any, item: any) => (acc += Number(item.amount)),
						0
					)
				);
				out2.children = [];
				out1.children.push(out2);

				additionalInfoMapper(
					out2,
					uniqueAdditionalInfo,
					filterdWithServiceName,
					said,
					serviceId,
					serviceName,
					iSaid,
					iServiceId,
					iServiceName
				);
			});
		}
	}

	function additionalInfoMapper(
		out2: any,
		uniqueAdditionalInfo: any,
		filterdWithServiceName: any,
		said: any,
		serviceId: any,
		serviceName: any,
		iSaid: any,
		iServiceId: any,
		iServiceName: any
	) {
		if (uniqueAdditionalInfo.length === 1) {
			let aditionalInfo = uniqueAdditionalInfo[0];
			let iAditionalInfo = 0;
			out2.data.additionalInfo = uniqueAdditionalInfo[0];
			const filterdWithAdditionalInfo = filterdWithServiceName.filter(
				(dataItem: any) => dataItem.aditionalInfo === aditionalInfo
			);
			const uniqueMonths = filterdWithAdditionalInfo
				.map((dataItem: any) => dataItem.serviceMonth)
				.filter(unique);

			const serviceResponsibleFinder = filterdWithServiceName.filter(
				(dataItem: any) => dataItem.aditionalInfo === uniqueAdditionalInfo[0]
			);
			const uniqueServiceResponsible = serviceResponsibleFinder
				.map((dataItem: any) => dataItem.targetResponsible)
				.filter(unique);
			const uniqueTargetCostCenter = serviceResponsibleFinder
				.map((dataItem: any) => dataItem.targetCostCenter)
				.filter(unique);
			const uniqueIoNumber = serviceResponsibleFinder
				.map((dataItem: any) => dataItem.ioNumber)
				.filter(unique);
			if (uniqueServiceResponsible.length === 1) {
				out2.data.targetResponsible = toUpperCase(uniqueServiceResponsible[0]);
			}
			if (uniqueTargetCostCenter.length === 1) {
				out2.data.targetCostCenter = uniqueTargetCostCenter[0];
			}

			if (uniqueIoNumber.length === 1) {
				out2.data.ioNumber = uniqueIoNumber[0];
			}

			monthMapper(
				out2,
				uniqueMonths,
				filterdWithAdditionalInfo,
				said,
				serviceId,
				serviceName,
				aditionalInfo,
				iSaid,
				iServiceId,
				iServiceName,
				iAditionalInfo
			);
		} else {
			uniqueAdditionalInfo.forEach(
				(aditionalInfo: any, iAditionalInfo: any) => {
					const filterdWithAdditionalInfo = filterdWithServiceName.filter(
						(dataItem: any) => dataItem.aditionalInfo === aditionalInfo
					);
					const uniqueMonths = filterdWithAdditionalInfo
						.map((dataItem: any) => dataItem.serviceMonth)
						.filter(unique);
					const out3: any = {};
					out3.key =
						iSaid +
						'-' +
						iServiceId +
						'-' +
						iServiceName +
						'-' +
						iAditionalInfo;
					out3.data = {};
					out3.data.said = said;
					out3.data.serviceId = serviceId;
					out3.data.serviceName = serviceName;
					out3.data.additionalInfo = aditionalInfo;
					out3.data.targetResponsible = '';
					out3.data.targetCostCenter = '';
					out3.data.serviceMonth = '';
					out3.data.ioNumber = '';
					out3.data.status = '';
					out3.data.total = formatCurrency(
						filterdWithAdditionalInfo.reduce(
							(acc: any, item: any) => (acc += Number(item.amount)),
							0
						)
					);
					out3.children = [];
					out2.children.push(out3);

					const uniqueServiceResponsible = filterdWithAdditionalInfo
						.map((dataItem: any) => dataItem.targetResponsible)
						.filter(unique);
					const uniqueTargetCostCenter = filterdWithAdditionalInfo
						.map((dataItem: any) => dataItem.targetCostCenter)
						.filter(unique);
					const uniqueIoNumber = filterdWithAdditionalInfo
						.map((dataItem: any) => dataItem.ioNumber)
						.filter(unique);
					if (uniqueServiceResponsible.length === 1) {
						out3.data.targetResponsible = toUpperCase(
							uniqueServiceResponsible[0]
						);
					}
					if (uniqueTargetCostCenter.length === 1) {
						out3.data.targetCostCenter = uniqueTargetCostCenter[0];
					}
					if (uniqueIoNumber.length === 1) {
						out3.data.ioNumber = uniqueIoNumber[0];
					}

					monthMapper(
						out3,
						uniqueMonths,
						filterdWithAdditionalInfo,
						said,
						serviceId,
						serviceName,
						aditionalInfo,
						iSaid,
						iServiceId,
						iServiceName,
						iAditionalInfo
					);
				}
			);
		}
	}
	function monthMapper(
		out3: any,
		uniqueMonths: any,
		filterdWithAdditionalInfo: any,
		said: any,
		serviceId: any,
		serviceName: any,
		aditionalInfo: any,
		iSaid: any,
		iServiceId: any,
		iServiceName: any,
		iAditionalInfo: any
	) {
		uniqueMonths.forEach((serviceMonth: any, iServiceMonth: any) => {
			const rx = filterdWithAdditionalInfo.filter(
				(dataItem: any) =>
					dataItem.said === said &&
					dataItem.serviceId === serviceId &&
					dataItem.serviceName === serviceName &&
					dataItem.aditionalInfo === aditionalInfo &&
					dataItem.serviceMonth === serviceMonth
			);

			const findCredited = rx.filter((x: any) => x.status === 'credited');
			const findBooked = rx.filter((x: any) => x.status === 'booked');
			findCredited.forEach((cr: any) => {
				let searchIndex = findBooked.findIndex(
					(x: any) =>
						x.status === 'booked' &&
						x.serviceMonth === cr.serviceMonth &&
						x.amount === -cr.amount
				);
				if (searchIndex !== -1) {
					findBooked.splice(searchIndex, 1);
				} else {
				}
			});

			findBooked.forEach((final: any, iFinal: any) => {
				const out4: any = {};
				out4.key =
					iSaid +
					'-' +
					iServiceId +
					'-' +
					iServiceName +
					'-' +
					iAditionalInfo +
					'-' +
					iServiceMonth +
					'-' +
					iFinal;
				out4.data = {};
				out4.data.said = said;
				out4.data.serviceId = serviceId;
				out4.data.serviceName = serviceName;
				out4.data.additionalInfo = aditionalInfo;
				out4.data.targetResponsible = toUpperCase(final.targetResponsible);
				out4.data.targetCostCenter = final.targetCostCenter;
				out4.data.serviceMonth = serviceMonth;
				out4.data.ioNumber = final.ioNumber;
				out4.data.status = final.status;
				out4.data.total = formatCurrency(final.amount);
				out3.children.push(out4);
			});
		});
	}
	const onColumnToggle = (event: any) => {
		setCols(event.value);
	};
	
	const isCostController = useSelector(getIsCostController);
	const header = (
		<>
			<div className="flex flex-column justify-content-between align-items-center">
				<div className="flex justify-content-between w-full align-items-center">
					<div className="flex align-items-baseline w-3">
						<h5>Charging Report Pivot</h5>
					</div>

					<div className="actions flex align-items-center justify-content-between mr-4">
						<div className="app-icon mr-2">
							<DisplayIcon icon="filter" />
						</div>
						<MultiSelect
							value={cols}
							options={colOptions}
							onChange={onColumnToggle}
							style={{ width: '250px' }}
							className="mr-3"
						/>
						<CustomAutoComplete
							customId="filter-active"
							placeholder="Filter By Year"
							inputList={[
								{ value: '2024', key: '2024' },
								{ value: '2023', key: '2023' },
								{ value: '2022', key: '2022' },
								{ value: '2021', key: '2021' },
								{ value: '2020', key: '2020' },
							]}
							field="key"
							target="value"
							callback={(e) => {
								if (e) {
									setSelectedYear(e.key);
								} else {
									setSelectedYear(new Date().getFullYear());
								}
							}}
						/>
					</div>
					<div className="flex align-items-center justify-content-end icon-section">
						<div className="flex flex-columns">
							<div className="flex">
								<div className="actions flex ml-4">
									{isAdmin && !isCostController && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Upload Charging Report"
											onClick={() =>
												dispatch(
													setDataProcessingModal({
														uploadChargingReport: true,
													})
												)
											}
										>
											<DisplayIcon icon="upload" className="action-icon" />
										</Button>
									)}
									<Button
										icon="pi pi-filter"
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Show Charging Report Pivot"
										onClick={() => {
											console.log('PROCESS :: ', process.env);
											window.open(
												`${
													process.env.REACT_APP_ENV === 'staging'
														? '/web/XCharge'
														: '#'
												}/dataProcessing/chargingReportPivot`,
												'_blank'
											);
										}}
									>
										<DisplayIcon icon="tablerTable" className="action-icon" />
									</Button>
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Show Wild Card Services"
											onClick={() => {
												console.log('PROCESS :: ', process.env);
												window.open(
													`${
														process.env.REACT_APP_ENV === 'staging'
															? '/web/XCharge'
															: '#'
													}/dataProcessing/reportUpload/wildCard`,
													'_blank'
												);
											}}
										>
											<DisplayIcon
												icon="docmentQuestion"
												className="action-icon"
											/>
										</Button>
									)}
									{isAdmin && (
										<Button
											icon="pi pi-filter"
											className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
											tooltip="Show New Services"
											onClick={() => {
												console.log('PROCESS :: ', process.env);
												window.open(
													`${
														process.env.REACT_APP_ENV === 'staging'
															? '/web/XCharge'
															: '#'
													}/dataProcessing/reportUpload/newServices`,
													'_blank'
												);
											}}
										>
											<DisplayIcon icon="add" className="action-icon" />
										</Button>
									)}
									<Button
										className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
										tooltip="Export Charging Report As Excel"
										tooltipOptions={{ position: 'left' }}
										onClick={() => {
											downloadFile(
												`chargingReport/export?year=${selectedYear}`,
												'Charging-Report_' + new Date().toISOString() + '.xlsx'
											);
										}}
									>
										<DisplayIcon icon="excel" className="excel-icon__small" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	function toUpperCase(name: any) {
		return name
			.split(',')
			.map(
				(item: any) => item.trim()[0].toUpperCase() + item.trim().substring(1)
			)
			.join(', ');
	}
	const [selectedNodeKeys3, setSelectedNodeKeys3] = useState<any>([]);

	let footerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					footer={
						'Selected Total : ' +
						formatCurrency(
							Object.values(nodes)
								.map((item: any) =>
									Number(
										item.replace(' €', '').replace('.', '').replace(',', '.')
									)
								)
								.reduce((acc: number, item: any) => (acc += item), 0)
						)
					}
					colSpan={10}
					footerStyle={{ textAlign: 'right' }}
				/>
			</Row>
		</ColumnGroup>
	);

	const loopChildrenAdd = (data: any) => {
		if (data.children === undefined) {
			setNodes(Object.assign(nodes, { [data.key]: data.data.total }));
		} else {
			for (let item of data.children) {
				loopChildrenAdd(item);
			}
		}
	};

	const loopChildrenRemove = (data: any) => {
		if (data.children === undefined) {
			delete nodes[data.key];
			setNodes(Object.assign(nodes));
			//setNodes(Object.assign(nodes, { [data.key]: data.data.total }));
		} else {
			for (let item of data.children) {
				loopChildrenRemove(item);
			}
		}
	};

	const onSelect = (event: any) => {
		loopChildrenAdd(event.node);
	};

	const onUnselect = (event: any) => {
		loopChildrenRemove(event.node);
	};

	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<TreeTable
						loading={isFetchingChargingReport}
						value={computedData}
						header={header}
						columnResizeMode="fit"
						selectionMode="checkbox"
						selectionKeys={selectedNodeKeys3}
						onSelectionChange={(e) => setSelectedNodeKeys3(e.value)}
						onSelect={onSelect}
						onUnselect={onUnselect}
						footerColumnGroup={footerGroup}
					>
						<Column
							key="said"
							field="said"
							header="SAID"
							expander
							style={{ width: '25%' }}
						/>
						{_columns}
					</TreeTable>
				</div>
			</div>
			<UploadChargingReport />
		</>
	);
};

export default ChargingReportPivot;
