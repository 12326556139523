import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getCommonModal, setCommonModal } from '../../store/commonSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetMappingUpdatesQuery } from '../../store/settingApi';

const ServiceDifference = () => {
	const {
		data: mappingUpdates = [],
		isLoading: isListLoading,
		isSuccess: isListSuccess,
		isError: isListError,
		error: listError,
	} = useGetMappingUpdatesQuery();

	const dispatch = useDispatch();
	const commonModal = useSelector(getCommonModal);
	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Received Notifications</h5>
				<div className="actions flex"></div>
			</div>
		);
	};

	return (
		<>
			<Dialog
				header="Services With Target Responsible Changed"
				visible={commonModal.serviceDifference}
				style={{ width: '100vw' }}
				onHide={() => dispatch(setCommonModal({ serviceDifference: false }))}
			>
				<div className={`grid`}>
					<div className="cards">
						<DataTable
							value={mappingUpdates}
							responsiveLayout="scroll"
							header={header}
						>
							<Column
								header="#"
								alignHeader="left"
								body={(data, props) => props.rowIndex + 1}
							></Column>
							<Column field="serviceId" header="Service ID"></Column>
							<Column field="serviceName" header="Service Name"></Column>
							<Column field="addInfo" header="Additional Info."></Column>
							<Column
								field="old_TargetCostCenter"
								header="Old Cost Center"
							></Column>
							<Column
								field="old_TargetResponsible"
								header="Old Target Responsible"
							></Column>
							<Column
								field="new_TargetCostCenter"
								header="New Cost Center"
							></Column>
							<Column
								field="new_TargetResponsible"
								header="New Target Responsible"
							></Column>
						</DataTable>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default ServiceDifference;
