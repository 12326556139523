import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { commonState } from '../../store/commonSlice';
import { useGetAllUsersQuery } from '../../store/UserApi';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../input-field/input-field';
import  ImageUploader  from '../image-uploader';

const addMappingSchema = Yup.object().shape({
	to: Yup.array().required(),
	cc: Yup.array().required(),
	bcc: Yup.array().required(),
	subject: Yup.string().required(),
	message: Yup.string().required(),
});

const Mailer = ({
	defaultTo,
	defaultCC,
	defaultBCC,
	defaultSubject,
	defaultMessage,
	hideTo,
	hideCC,
	hideBCC,
	showAttachment,
	subjectPlaceholder,
	EditorPlaceHolder,
	callback,
}: {
	defaultTo?: string[];
	defaultCC?: string[];
	defaultBCC?: string[];
	defaultSubject?: string;
	defaultMessage?: string;
	hideTo?: boolean;
	hideCC?: boolean;
	hideBCC?: boolean;
	showAttachment?: boolean;
	subjectPlaceholder?: string;
	EditorPlaceHolder?: string;
	callback?: (e?: any) => any;
}) => {
	const dispatch = useDispatch();
	const common = useSelector(commonState);
	const { data, isLoading, isSuccess, isError, error } = useGetAllUsersQuery();
	const [formData, setFormData] = useState<any>({});
	const [selectedImage, setSelectedImage] = useState<File[]>();
	const formik = useFormik({
		initialValues: {
			to: [],
			cc: [],
			bcc: [],
			subject: defaultSubject ? defaultSubject : '',
			message: defaultMessage ? defaultMessage : '',
		},
		validationSchema: addMappingSchema,
		onSubmit: (data) => {
			setFormData(data);
			formik.resetForm();
		},
		validateOnBlur: true,
	});

	useEffect(() => {
		if (showAttachment) {
			if (callback)
				callback({
					emailIds: formik.values.to,
					ccIds: formik.values.cc,
					bccIds: formik.values.bcc,
					subject: formik.values.subject,
					message: formik.values.message,
					rawdata: selectedImage
				});
		} else {
		if (callback)
			callback({
				emailIds: formik.values.to,
				ccIds: formik.values.cc,
				bccIds: formik.values.bcc,
				subject: formik.values.subject,
				message: formik.values.message,
			});
		}
	}, [formik.values, selectedImage]);
	const [filteredValues, setFilteredValues] = useState<any>();
	const searchCountry = (event: { query: string }) => {
		setTimeout(() => {
			let _filteredValues;
			if (!event.query.trim().length) {
				_filteredValues = data && [...data.map((d) => ({ email: d.email }))];
			} else {
				_filteredValues =
					data &&
					data
						.map((d) => ({ email: d.email }))
						.filter((listValue) =>
							Object.values(listValue)
								.map((entry: any) => entry.toString().toLocaleLowerCase())
								.find((item) => item.includes(event.query.toLocaleLowerCase()))
						);
				console.log(_filteredValues, 'F');
			}
			setFilteredValues(_filteredValues);
		}, 100);
	};

	const imageCallback = (file: File[] | undefined) => {
		setSelectedImage(file);
	};

	return (
		<div className="p-fluid grid formgrid mt-5">
			{data && (
				<>
					{!hideTo && (
						<InputField
							colSpan={12}
							type="autocomplete"
							id="to"
							target="email"
							formik={formik}
							inputList={data && data.map((d) => ({ email: d.email }))}
							field="to"
							multiple
							label="To"
							callback={(e) =>
								formik.setFieldValue('to', [
									...e.map((email: any) => email.email),
								])
							}
							hideClear={true}
							defaultValue={defaultTo?.map((e) => ({
								email: e,
							}))}
						/>
					)}
					{!hideCC && (
						<InputField
							type="autocomplete"
							colSpan={6}
							id="cc"
							target="email"
							formik={formik}
							inputList={data && data.map((d) => ({ email: d.email }))}
							field="cc"
							multiple
							label="CC"
							callback={(e) =>
								formik.setFieldValue('cc', [
									...e.map((email: any) => email.email),
								])
							}
							hideClear={true}
							defaultValue={defaultCC?.map((e) => ({
								email: e,
							}))}
						/>
					)}
					{!hideBCC && (
						<InputField
							colSpan={6}
							type="autocomplete"
							id="bcc"
							target="email"
							formik={formik}
							inputList={data && data.map((d) => ({ email: d.email }))}
							field="bcc"
							multiple
							label="BCC"
							callback={(e) =>
								formik.setFieldValue('bcc', [
									...e.map((email: any) => email.email),
								])
							}
							hideClear={true}
							defaultValue={defaultBCC?.map((e) => ({
								email: e,
							}))}
						/>
					)}
					<InputField
					type='input'
						colSpan={12}
						field="subject"
						formik={formik}
						label="Subject"
						placeholder={subjectPlaceholder}
						defaultValue={defaultSubject}
					/>
					
					{ showAttachment &&  <ImageUploader imageCallback={imageCallback} /> }

					<Editor
						style={{ height: '320px' }}
						value={formik.values.message || defaultMessage}
						placeholder={EditorPlaceHolder}
						onTextChange={(e: any) =>
							formik.setFieldValue('message', e.htmlValue)
						}
						className="w-full"
					/>

					{/* <InputField
						colSpan={12}
						type="textarea"
						field="message"
						formik={formik}
						label="Message"
					/> */}
				</>
			)}
		</div>
	);
};

export default Mailer;
